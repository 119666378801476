@charset "UTF-8";

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  FONTS */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Roboto:wght@300;400;500;700&display=swap');

@media all {
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  VARIABLES */
:root {
/* - - - - - - - - - - - - - - COLORS */
--cbg: #133845;
--c1: #9fe606;
--c1-t1: rgba(159, 230, 6, .125);
--c1-t2: rgba(159, 230, 6, .25);
--c2: #f89c2d;
--c4-kk: #1f4247;
--c4-k: #313838;
--c4: #339999;
--c4-m: #66b2b2;
--c4-l: #34fafa;
--c4-t7: rgba(51, 153, 153, .75);
--c4-t5: rgba(51, 153, 153, .5);
--c4-t3: rgba(51, 153, 153, .35);
--c4-t2: rgba(51, 153, 153, .25);
--c5: #4f9ffb;
--c7: #f00;
--c7-l: #ff6633;
--c7-k: #b34824;
--gc: #c3c6c6;
--ga: #a6a9a9;
--g9: #969999;
--g8: #838686;
--g6: #636666;
--g5: #505353;
--g35: #393c3c;
--g3: #303333;
--g1: #181a1a;
--g0: #1d232a;
--kt-7: rgba(0, 0, 0, .7);
--kt-6: rgba(0, 0, 0, .6);
--kt-5: rgba(0, 0, 0, .5);
--kt-25: rgba(0, 0, 0, .25);
--g7t: rgba(115, 115, 115, .65);
--g6t: rgba(102, 102, 102, .75);
--bgi-1: rgba(51, 153, 153, .25);
--tooltxt: #fff;
--tooltip: #006666;
--tooltxt: #333;
--tooltip: #fff;
/* BITWILD PARTNERS */
--bwp-1: #1f7cf2;
--bwp-2: #6635c1;
--bwp-3: #1fc1f2;
/* CIRCUITOS */
--cir-1: #1F4247;

--acc2ContH: 300px;
--acc2ContH2: 300px;
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  / VARIABLES */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  RESET */
* {
margin: 0;
padding: 0;
box-sizing: border-box;
-webkit-tap-highlight-color: transparent;
scroll-behavior: smooth;
-webkit-overflow-scrolling: touch;
text-size-adjust: none;
-webkit-text-size-adjust: none;
-moz-text-size-adjust: none;
-ms-text-size-adjust: none;
}
html, body { width: 100%; height: 100%; }
html {
height: 100%;
font-size: 1.15px;
}
body {
height: calc(100%);
/* padding-left: env(safe-area-inset-left); */
/* padding-right: env(safe-area-inset-right); */
/* padding-left: env(safe-area-inset-left); */
padding-right: 0;
padding-left: 0;
font-family: Roboto, sans-serif;
font-size: 12rem;
color: #fff;
background: #000;
background-size: cover;
position: absolute;
touch-action: manipulation;
touch-action: pan-y !important;
}
/* .main-cover{ width: 100%; height: 100%; position: fixed; left: 0; top: 0; content: ''; background-color: rgba(42,108,140,.25); } */

/* ######################### FULL SCREEN BG */
.fullscreen-bg {
width: 100vw;
height: calc(100%);
position: fixed;
left: 0;
top: 0;
background: var(--cbg) url(./assets/videos/bg_video.jpg) no-repeat center;
background-size: cover;
overflow: hidden;
z-index: -1;
}
.fullscreen-bg.afiliado { background: #fff }
.fullscreen-bg_cover {
width: 100vw;
height: calc(100%);
position: fixed;
left: 0;
top: 0;
background-color: rgba(42, 108, 140, .25);
}
/* ######################### / FULL SCREEN BG */

/* ######################### MAIN CONTAINER */
#loader-component {
width: 100vw;
height: 100vh;
position: fixed;
top: 0;
left: 0;
z-index: 9999999;
}
#main-container {
width: 100%;
height: 100%;
position: absolute;
overflow: hidden;
background-color: rgba(0,0,0,.5);
}

ul { list-style-type: none }
img { max-width: 100% }
table { width: 100%; border-collapse: collapse }
hr { height: 1rem; border: none; background-color: #333 }
.hr-l { background-color: var(--g5) }
.hr-4 { background-color: var(--c4-kk) }
iframe { width: 100%; height: 100%; border: none }
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  / RESET */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  SCROLLBARS */
::-webkit-scrollbar { width: 4px; 	height: 4px }
::-webkit-scrollbar-track { background-color: var(--kt-25) }
::-webkit-scrollbar-thumb { background-color: var(--c4); border: 1px solid var(--cbg) }
::-webkit-scrollbar-corner { display: none;
/* border: 1px solid rgba(152, 153, 153, .25) */
}
/*
::-webkit-scrollbar-button{display: none;}
::-webkit-scrollbar-track-piece{}
::-webkit-resizer{}
*/
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / SCROLLBARS */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - FORM */
/* - - - - - - - - - - - - - - - PLACEHOLDER */
::-webkit-input-placeholder { color: var(--c4) }
::-moz-placeholder { color: var(--c4) }
:-ms-input-placeholder { color: var(--c4) }
:-moz-placeholder { color: var(--c4) }
/* - - - - - - - - - - - - - - - / PLACEHOLDER */

button, input, select, textarea {
display: inline-block;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
position: relative;
font-family: Roboto, sans-serif;
background-color: transparent;
border: none;
outline: none;
transition: all 0 ease-in-out;
}

/* - - - - - - - - - - - - - - - BUTTONS */
button, button:hover { 	transition: all .25s cubic-bezier(.39, .575, .565, 1) }
button:active { transition-duration: 0s }

button, button * {
-ms-user-select: none;
user-select: none;
-moz-user-select: none;
-webkit-user-select: none;
}

.btn img { pointer-events: none }
.btn .icon-full {
width: 100%;
height: 100%;
}

#circuit-container #btn-ctrl-closeDown.btn-ctrl-container {
width: auto;
height: auto;
padding: 0;
position: fixed;
right: 10rem;
top: calc(100% + 7rem);
border-radius: 50%;
transition: all .25s ease-in-out;
}
#circuit-container.active #btn-ctrl-closeDown.btn-ctrl-container {
top: 7rem;
}

.btn-xl {
width: 40rem;
height: 40rem;
border: 2rem solid #fff;
}
.btn-lg {
width: 42rem;
height: 42rem;
border: 2rem solid #fff;
}
.btn-nor {
width: 36rem;
height: 36rem;
font-size: 12rem;
border: 1rem solid #fff;
}
#catalog .btn-nor {
width: 30rem;
height: 30rem;
border: 1rem solid var(--c1);
}
.btn-sm {
width: 24rem;
height: 24rem;
border: 1rem solid #fff;
}
.btn-xl.btn-rnd { border-radius: 20rem }
.btn-lg.btn-rnd { border-radius: 23rem }
.btn-nor.btn-rnd { border-radius: 18rem }
.btn-sm.btn-rnd { border-radius: 12rem }

.btn-sqr { border-radius: 4rem }

.btn-1st,
.btn-1st:hover {
color: #fff;
border-color: var(--c1);
}
.btn-1st:active {
color: #000;
background-color: var(--c1);
}
.btn-1st:hover img {
-webkit-filter: none;
filter: none
}
.btn-1st:active img {
-webkit-filter: brightness(0);
filter: brightness(0)
}
.btn-1st.open {
color: #000;
background-color: var(--c1);
}
.btn-1st.open img {
-webkit-filter: brightness(0);
filter: brightness(0)
}

.btn-1st.btn-log img,
.btn-1st.btn-log:hover img {
-webkit-filter: invert(71%) sepia(78%) saturate(825%) hue-rotate(28deg) brightness(103%) contrast(95%);
filter: invert(71%) sepia(78%) saturate(825%) hue-rotate(28deg) brightness(103%) contrast(95%);
}
.btn-1st.btn-log:active img {
-webkit-filter: none;
filter: none
}

.btn-1st.btn-log:active img {
-webkit-filter: none;
filter: none
}

.btn-2nd,
.btn-2nd:hover {
color: #fff;
border-color: var(--c2);
}
.btn-2nd:active {
color: #000;
background-color: var(--c2);
}
.btn-2nd:hover img {
-webkit-filter: none;
filter: none
}
.btn-2nd:active img {
-webkit-filter: brightness(0);
filter: brightness(0)
}

.btn-2nd.btn-log img,
.btn-2nd.btn-log:hover img {
-webkit-filter: invert(68%) sepia(71%) saturate(1442%) hue-rotate(338deg) brightness(101%) contrast(95%);
filter: invert(68%) sepia(71%) saturate(1442%) hue-rotate(338deg) brightness(101%) contrast(95%);
}
.btn-2nd.btn-log:active img {
-webkit-filter: none;
filter: none
}

.btn-4th,
.btn-4th:hover {
color: #fff;
border-color: var(--c4);
}
.btn-4th:active { background-color: var(--c4) }
.btn-4th:hover img {
-webkit-filter: none;
filter: none
}
.btn-4th:active img {
-webkit-filter: brightness(0) invert(1);
filter: brightness(0) invert(1);
}

.btn-4th.favorite img,
.btn-4th.favorite:hover img,
.btn-4th.favorite:active img {
-webkit-filter: brightness(50%) sepia(100) saturate(100) hue-rotate(25deg);
filter: brightness(50%) sepia(100) saturate(100) hue-rotate(25deg)
}

.btn-4th-l,
.btn-4th-l:hover {
color: #fff;
border-color: var(--c4-l) !important;
}
.btn-4th-l:active { background-color: var(--c4-l) }
.btn-4th-l:hover img {
-webkit-filter: none;
filter: none
}
.btn-4th-l:active img {
-webkit-filter: brightness(0);
filter: brightness(0)
}

.btn-7th,
.btn-7th:hover {
color: #fff;
border-color: var(--c7-l);
}
.btn-7th:active { background-color: var(--c7-l) }
.btn-7th img,
.btn-7th:hover img {
-webkit-filter: none;
filter: none
}
.btn-7th:active img {
-webkit-filter: brightness(0) invert(1);
filter: brightness(0) invert(1)
}

.btn-8th,
.btn-8th:hover {
color: var(--cir-1);
border-color: var(--cir-1) !important;
}
.btn-8th:active {
color: #fff;
background-color: var(--cir-1);
}
.btn-8th img,
.btn-8th:hover img {
-webkit-filter: invert(19%) sepia(73%) saturate(318%) hue-rotate(139deg) brightness(92%) contrast(92%);
filter: invert(19%) sepia(73%) saturate(318%) hue-rotate(139deg) brightness(92%) contrast(92%);
}
.btn-8th:active img {
-webkit-filter: brightness(0) invert(1);
filter: brightness(0) invert(1)
}

.btn-7th-7,
.btn-7th-7:hover {
color: #fff;
background-color: rgba(0,0,0,.75);
border-color: var(--c7-l);
}
.btn-7th-7:active { background-color: var(--c7-l) }
.btn-7th-7 img {
/* -webkit-filter: brightness(0) invert(1);
filter: brightness(0) invert(1) */
-webkit-filter: invert(48%) sepia(33%) saturate(2057%) hue-rotate(336deg) brightness(102%) contrast(105%);
        filter: invert(48%) sepia(33%) saturate(2057%) hue-rotate(336deg) brightness(102%) contrast(105%);
}
.btn-7th-7:active img,
.btn-7th-7:hover img {
-webkit-filter: brightness(0);
filter: brightness(0)
}

.btn-g,
.btn-g:hover {
color: var(--gc);
border-color: var(--g6);
}
.btn-g:active { background-color: var(--c4-l) }
.btn-g:hover img {
-webkit-filter: none;
filter: none
}
.btn-g:active img {
-webkit-filter: brightness(0);
filter: brightness(0)
}

.btn-w,
.btn-w:hover { border: 1rem solid #fff }

.btn-w:active { background-color: #fff }
.btn-w:hover img {
-webkit-filter: none;
filter: none
}
.btn-w:active img {
-webkit-filter: brightness(0);
filter: brightness(0)
}

.btn-w-close {
padding: 5px 5px 10px 10px;
position: absolute;
top: 0;
right: 0;
border-color: transparent;
}
.btn-w-close img,
.btn-w-close:hover img {
width: 36px;
height: 36px;
}
.btn-w-close:active img {
-webkit-filter: grayscale(1) brightness(0) invert(1);
filter: grayscale(1) brightness(0) invert(1);
}

.btn-nor.btn-icon {
padding-right: 36rem;
padding-left: 18rem;
text-align: left;
}
.btn-icon img {
width: 34rem;
height: 34rem;
position: absolute;
right: 0;
top: 0;
}

.btn-sm img {
width: 22rem;
height: 22rem;
position: absolute;
right: 0;
top: 0;
}

.btn-edit, .btn-save, .btn-discard {
overflow: hidden;
border: none;
}
.btn-edit, .btn-edit:hover { background-color: var(--c4) }
.btn-save, .btn-save:hover { background-color: var(--c1) }
.btn-discard, .btn-discard:hover { background-color: var(--c7) }

.btn-edit img,
.btn-edit:hover img,
/* .btn-save img, */
.btn-discard img,
.btn-discard:hover img {
-webkit-filter: brightness(0) invert(1);
filter: brightness(0) invert(1);
}

.btn-edit:active, .btn-save:active, .btn-discard:active { background: #fff }

.btn-edit:active img,
.btn-discard:active img {
-webkit-filter: none;
filter: none;
}

button:disabled { cursor: not-allowed }

/* - - - - - - - - - - - - - - - BUTTONS 2 */
.btn2 { border-radius: 4rem }
.btn2-nor {
height: 36rem;
padding-right: 15rem;
padding-left: 15rem;
}
.btn2-nor-icon { padding-left: 36rem }
.btn2-nor-icon img {
width: 29rem;
height: 29rem;
position: absolute;
left: 2rem;
top: 2rem;
}
.btn2-lg {
min-width: 44rem;
height: 44rem;
padding-right: 15rem;
padding-left: 15rem;
font-size: 14rem;
}
/* .btn2-lg-icon { padding-left: 40rem } */
.btn2-lg-icon img {
width: 32rem;
height: 32rem;
position: absolute;
left: 4rem;
top: 4rem;
}
.btn2-sm {
min-width: 30rem;
height: 30rem;
padding-right: 5rem;
padding-left: 5rem;
}
.btn2-sm-icon img {
width: 23rem !important;
height: 23rem !important;
position: absolute;
left: 2rem;
top: 2rem;
}

.btn2-1st {
color: #fff;
border: 2px solid var(--c1);
}
.btn2-1st:active {
color: #000;
background-color: var(--c1);
}
.btn2-1st:active img {
-webkit-filter: brightness(0);
filter: brightness(0);
}
.btn2-1st.btn2-1st-1st img {
-webkit-filter: brightness(0) saturate(100%) invert(78%) sepia(68%) saturate(893%) hue-rotate(25deg) brightness(98%) contrast(95%);
        filter: brightness(0) saturate(100%) invert(78%) sepia(68%) saturate(893%) hue-rotate(25deg) brightness(98%) contrast(95%);
}
.btn2-1st.btn2-1st-1st:active img { -webkit-filter: none; filter: none }

.btn2-2nd {
color: #fff;
border: 2px solid var(--c2);
}
.btn2-2nd:active {
color: #000;
background-color: var(--c2);
}
.btn2-2nd:active img {
-webkit-filter: brightness(0);
filter: brightness(0);
}
.btn2-4th {
color: #fff;
border: 2px solid var(--c4);
}
.btn2-4th:active {
color: #000;
background-color: var(--c4);
}
/* .btn2-4th img {} */
.btn2-4th:active img {
-webkit-filter: brightness(0);
filter: brightness(0);
}
.btn2-7th {
color: #fff;
border: 2px solid var(--c7-l);
}
.btn2-7th:active {
color: #000;
background-color: var(--c7-l);
}
.btn2-7th img {
-webkit-filter: brightness(0) saturate(0) invert(46%) sepia(97%) saturate(1599%) hue-rotate(339deg) brightness(101%) contrast(101%);
filter: brightness(0) saturate(0) invert(46%) sepia(97%) saturate(1599%) hue-rotate(339deg) brightness(101%) contrast(101%);;
}
.btn2-7th:active img {
-webkit-filter: brightness(0);
filter: brightness(0);
}

.btn2-g {
color: var(--gc);
border: 2px solid var(--g6);
}
.btn2-g:active {
color: #fff;
background-color: var(--g6);
}

.btn2-log img {
-webkit-filter: brightness(0) saturate(100) invert(85%) sepia(29%) saturate(3257%) hue-rotate(26deg) brightness(98%) contrast(102%);
filter: brightness(0) saturate(100) invert(85%) sepia(29%) saturate(3257%) hue-rotate(26deg) brightness(98%) contrast(102%);
}

.btn2-fb {
color: #fff;
border: 2px solid #1877f1;
}
.btn2-fb:active {
color: #1877f1;
background-color: #fff;
border-color: #fff;
}

.btn2-1st-afil {
color: #fff;
background-color: var(--bwp-2);
border: 2px solid var(--bwp-2);
}
.btn2-1st-afil img {
-webkit-filter: brightness(0) invert(1);
filter: brightness(0) invert(1);
}
.btn2-1st-afil:active {
background-color: var(--c4-k);
border: 2px solid var(--c4-k);
}

.btn2-fb-afil {
color: #fff;
background-color: var(--bwp-1);
border: 2px solid var(--bwp-1);
}
.btn2-fb-afil img {
-webkit-filter: brightness(0) invert(1);
filter: brightness(0) invert(1);
}
.btn2-fb-afil:active {
background-color: var(--c4-k);
border-color: var(--c4-k);
}

.btn2:disabled {
border-color: var(--c7);
opacity: .25
}
/* - - - - - - - TOOLTIP 2 */
.tooltip2 {
width: 44rem;
height: 44rem;
padding: 10rem;
}
.tooltip2-inline-container {
width: 100%;
height: 16rem;
transform: translateY(-3rem);
}
.tooltip2-inline {
width: 16rem;
height: 16rem;
padding: 0;
transform: translateY(-3rem);
}
.tooltip2 img{ -webkit-filter: saturate(0) brightness(.75); filter: saturate(0) brightness(.75) }
.tooltip2-contentainer {
position: relative;
color: #fff;
background-color: var(--g6);
z-index: 10;
}
.tooltip2-contentainer::after {
width: 0;
height: 0;
position: absolute;
content: '';
}
.tooltip2-content {
max-height: 0;
padding: 0 15rem;
overflow: hidden;
}
.tooltip2-contentainer.active .tooltip2-content {
max-height: 100rem;
padding: 15rem;
}
.tooltip2-contentainer,
.tooltip2-contentainer::after,
.tooltip2-content { transition: all .15s ease-in-out;}

.tooltip2-contentainer.RU::after {
right: 22rem;
top: 0;
border: 0 solid transparent;
}
.tooltip2-contentainer.RU.active::after {
right: 17rem;
top: -20rem;
border: 10rem solid transparent;
border-bottom-color: var(--g6);
}

.tooltip2-inline + .tooltip2-contentainer {
width: 100%;
position: absolute;
left: 0;
top: 26rem;
z-index: 91010;
}
.tooltip2-inline + .tooltip2-contentainer.RU::after {
right: 5rem;
}
.tooltip2-inline + .tooltip2-contentainer.RU.active::after {
right: 0;
}
/* - - - - - - - / TOOLTIP 2 */

.btn-window-close {
width: 100%;
padding: 10rem 0;
text-align: center;
cursor: pointer;
}
.btn-window-close-bar {
display: block;
width: 100rem;
height: 3rem;
margin: 0 auto;
background-color: var(--g3);
border-radius: 1rem;
}
.btn-window-close:active .btn-window-close-bar { background-color: var(--c1) }

.btn-m-help,
.btn-m-help:hover {
width: 64rem;
height: 44rem;
position: relative;
z-index: 2;
cursor: pointer;
}
.btn-m-help:active img {
-webkit-filter: brightness(0) invert(1);
filter: brightness(0) invert(1);
}
/* - - - - - - - - - - - - - - - / BUTTONS 2 */

.home-header-container {
height: 38rem;
display: flex;
flex-flow: row nowrap;
justify-content: flex-end;
align-items: flex-end;
}
.btn-menu-visitor {
min-width: 38rem;
height: 38rem;
padding: 4rem;
display: block;
cursor: pointer;
}
.btn-menu-visitor.bm-user { width: 38rem; }
.btn-menu-visitor.bm-user img,
.btn-menu-visitor.bm-user:hover img{
-webkit-filter: brightness(0) saturate(100%) invert(39%) sepia(14%) saturate(1173%) hue-rotate(131deg) brightness(87%) contrast(93%);
filter: brightness(0) saturate(100%) invert(39%) sepia(14%) saturate(1173%) hue-rotate(131deg) brightness(87%) contrast(93%);
}
.btn-menu-visitor.bm-user:active img {
-webkit-filter: none;
filter: none;
}

.btn-menu-visitor.bm-lang #lang-in-use {
width: 36rem;
font-size: 9rem;
line-height: 18rem;
color: #477;
border: 1px solid #477;
border-radius: 4rem;
}
.btn-menu-visitor.bm-lang.active #lang-in-use {
color: #fff;
background-color: var(--c4);
}

#btn-homeV-help {
position: fixed;
right: 12rem;
bottom: 12rem;
}

/* - - - - - - - - - - - - - - - INPUTS */
.inp, .inp:hover,
.inp2 { display: inline-block }

.inp-nor {
height: 36rem;
padding: 0 5rem;
font-size: 14rem;
border: 1rem solid #fff;
border-radius: 4rem;
}
.inp-sm {
height: 28rem;
padding: 0 5rem;
font-size: 14rem;
border: 1rem solid #fff;
border-radius: 4rem;
}

.inp-1st, .inp-1st:hover {
color: #fff;
background-color: var(--bgi-1);
border-color: var(--c4-l);
}
.inp-1st:focus, .inp-1st:active { background-color: var(--kt-7) }

.inp-4th {
color: #fff;
background-color: var(--bgi-1);
border-color: var(--c4-l);
}

/* .date {} */
.date-txt {
position: relative;
pointer-events: none;
z-index: 1;
background-repeat: no-repeat;
background-position: right center;
}
.date-txt.inp-nor { background-size: contain }
.date-txt.inp-4th { background-image: url(./assets/images/icon-date4.svg) }
.date-inp {
position: absolute;
top: 0;
left: 0;
z-index: 2;
opacity: 0;
}
[type="date"] { padding: 0 !important }
[type="date"]::-webkit-inner-spin-button { display: none }
[type="date"]::-webkit-calendar-picker-indicator {
width: 100%;
height: 100%;
position: absolute;
left: -30rem;
top: 0;
background: red;
}
.date .inp2-nor {
background-size: contain;
background-image: url(./assets/images/icon-date-g.svg);
}

/* .file {} */
[type="file"] {
width: 30rem;
height: 30rem;
position: absolute;
top: 0;
left: 0;
background-color: red;
opacity: 0;
overflow: hidden;
}
/* - - - - - - - - - - - - - - - PLACEHOLDER */
.file-inp-txt::-webkit-input-placeholder { color: var(--g6) }
.file-inp-txt::-moz-placeholder { color: var(--g6) }
.file-inp-txt:-ms-input-placeholder { color: var(--g6) }
.file-inp-txt:-moz-placeholder { color: var(--g6) }
/* - - - - - - - - - - - - - - - / PLACEHOLDER */

/* AUTOFILL */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
color: #fff;
-webkit-text-fill-color: #fff;
background-color: var(--kt-7) !important;
/* border: 1rem solid var(--c4-l) !important; */
box-shadow: none !important;
-webkit-box-shadow: none !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
transition-delay: 30000s;
}
/* / AUTOFILL */

.inp:focus, .inp:active { background-color: var(--kt-7) }

.inp::-moz-selection {
color: #fff;
background-color: var(--c4);
}
.inp::selection {
color: #fff;
background-color: var(--c4);
}

.inp-1st:disabled { border-color: var(--c4) }

.faux-inp {
border: 1rem solid var(--g5);
padding: 10rem;
position: relative;
border-radius: 2rem;
}
.faux-inp .act {
margin-top: -12rem;
position: absolute;
top: 50%;
right: 6rem;
}
.faux-inp-nor {
padding: 10rem;
font-size: 16rem;
}
.faux-inp-sm {
padding: 5rem 10rem;
font-size: 16rem;
}
.fi-coin,
.faux-inp-nor .fi-coin {
width: auto;
height: 24rem;
margin-right: 5rem;
display: inline-block;
vertical-align: middle;
}
.faux-inp-sm .fi-coin {
width: auto;
height: 24rem;
margin-right: 5rem;
display: inline-block;
vertical-align: middle;
}

.faux-inp.inp-4th {
color: #fff;
background-color: var(--bgi-1);
border-color: var(--c4-l);
border-radius: 4rem;
}

#bono-activate-1 .faux-inp.of-y {
height: 60rem;
padding-left: 5rem;
}

.faux-inp-nor.fi-hash {
height: auto;
min-height: 24rem;
word-break: break-all;
}
/* .inp:read-only {} */

/* - - - - - - - - - - - - - - - IMPUTS 2 */
.inp2 { border-radius: 4rem }
.inp2-sm {
height: 30rem;
padding: 0 10rem;
font-size: 12rem;
color: #fff;
border: 1rem solid var(--g6);
}
.inp2-nor {
height: 36rem;
padding: 0 10rem;
font-size: 16rem;
color: #fff;
border: 1rem solid var(--g6);
}
.inp2-nor:focus {
background-color: #000;
border-color: var(--g9);
}

.inp2:disabled {
color: var(--gc);
border-color: var(--g3);
}
.inp2-nor::-webkit-input-placeholder { color: var(--g9) }
.inp2-nor::-moz-placeholder { color: var(--g9) }
.inp2-nor:-ms-input-placeholder { color: var(--g9) }
.inp2-nor:-moz-placeholder { color: var(--g9) }

.faux-inp2 {
padding: 10rem;
font-size: 16rem;
color: #fff;
border: 1rem solid var(--g6);
border-radius: 4rem;
}
/* - - - - - - - - - - - - - - - / IMPUTS 2 */

/* - - - - - - - - - - - - - - - TEXTAREAS */
.ta,
.ta2 {
padding: 5rem;
border: 1rem solid #333;
resize: none;
}
.ta:focus { background-color: var(--kt-7) }

.ta2 {
border: 1rem solid var(--g6);
border-radius: 4rem;
}
.ta2-nor { height: 44rem }
.ta2-xlg { height: 200rem }

.faux-ta2-nor {
height: 44rem;
padding: 5rem 10rem;
display: flex;
justify-content: center;
align-items: center;
border: 1rem solid var(--g6);
}

/* - - - - - - - - - - - - - - - / TEXTAREAS */
/* - - - - - - - - - - - - - - - SELECTS */
.sel::after {
width: 0;
height: 0;
display: block;
position: absolute;
right: 6rem;
top: 16rem;
content: '';
border-style: solid;
border-width: 6rem 5rem 5rem 4rem;
border-color: #fff transparent transparent transparent;
}
.sel.sel-sm::after {
right: 6rem;
top: 12rem;
}

.sel-nor {
height: 36rem;
padding: 0 20rem 0 5rem;
font-size: 14rem;
border: 1rem solid #fff;
border-radius: 4rem;
}
.sel .sel-sm {
height: 28rem;
padding: 0 16rem 0 5rem;
font-size: 14rem;
border: 1rem solid #fff;
border-radius: 4rem;
}
.sel .sel-sm.sel-1st { border-color: var(--c4-l);}

.sel-1st::after { border-color: var(--c4-l) transparent transparent transparent }
.sel-1st select {
color: #fff;
background-color: var(--bgi-1);
border-color: var(--c4-l) !important;
}

.sel-1st.disabled::after { border-color: var(--g6) transparent transparent transparent }
.sel.disabled select,
.sel-1st.disabled select { border-color: var(--c4) !important; pointer-events: none }

.faux-sel input {
padding-right: 22rem;
pointer-events: none;
}
.faux-sel select {
position: absolute;
left: 0;
top: 0;
opacity: 0;
z-index: 1;
}
/* - - - - - - - - - - - - - - - SELECTS 2 */
.sel2 { position: relative }
.sel2::after {
width: 0;
height: 0;
display: block;
position: absolute;
right: 10rem;
top: 15rem;
content: '';
border-style: solid;
border-width: 8rem 6rem;
border-color: var(--g8) transparent transparent transparent;
}

.sel2-nor {
height: 36rem;
padding: 0 10rem;
font-size: 16rem;
color: #fff;
border: 1rem solid var(--g6);
border-radius: 4rem;
}
.sel2-nor:focus {
background-color: #000;
border-color: var(--g9);
}

.faux-sel2 input {
padding-right: 28rem;
pointer-events: none;
}
.faux-sel2 select {
position: absolute;
left: 0;
top: 0;
opacity: 0;
z-index: 1;
}
.faux-sel2.sel2-only select {
position: relative;
opacity: 1;
}
.sel2:disabled {
color: var(--gc);
border-color: var(--g3);
}

/* - - - - - - - - - - - - - - - / SELECTS 2 */
/* - - - - - - - - - - - - - - - CHECKBOX */
.chb { position: relative }

.chb span {
position: relative;
z-index: 2;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
pointer-events: none;
}
.chb span a { pointer-events: all }

.chb-nor {
padding-left: 30rem;
font-size: 14rem;
line-height: 20rem;
color: var(--g9);
}
.chb-nor a,
.chb-nor a:hover {
text-decoration: none;
color: var(--g9);
border-bottom: 1rem solid var(--g6);
}
.chb-nor a:active { color: var(--gc) }
.chb input {
width: 100%;
height: 24rem;
position: absolute;
top: 0;
left: 0;
z-index: 1;
}
.chb-nor input::after {
width: 20rem;
height: 20rem;
display: block;
position: absolute;
left: 0;
top: 0;
content: '';
border: 1rem solid #ccc;
border-radius: 4rem;
}

.chb-4th input::after { border-color: var(--c4-l) }
.chb-4th input:checked::after {
background: var(--c4-l) url(./assets/images/icon-chb4.svg) no-repeat center;
background-size: cover;
}
/* - - - - - - - - - - - - - - - / CHECKBOX */

/* - - - - - - - - - - - - - - - RADIO */
.rad {
padding-left: 30rem;
font-size: 16rem;
line-height: 28rem;
border: 1rem solid var(--g6);
border-radius: 15rem;
}
.rad input {
width: 100%;
height: 30rem;
position: absolute;
left: 0;
top: 0;
background-color: red;
opacity: 0;
z-index: 2;
}

.rad.rad-4th::before {
width: 16rem;
height: 16rem;
position: absolute;
left: 5rem;
top: 5rem;
content: '';
border: 2rem solid var(--c4-l);
border-radius: 50%;
}
.rad.rad-4th.checked { border-color: var(--c4) }
.rad.checked::after {
width: 12rem;
height: 12rem;
position: absolute;
left: 9rem;
top: 9rem;
content: '';
background-color: #fff;
border-radius: 50%;
}
/*
.rad { width: 18rem; height: 18rem; display: inline-block; vertical-align: middle; border: 1rem solid #ccc; border-radius: 50%; position: relative; transform: translateY(-1rem); }
*/
/* - - - - - - - - - - - - - - - / RADIO */

/* - - - - - - - - - - - - - - - PROGRESS */
.file-upload {
width: 100%;
background-color: var(--g1);
}
.progress-c {
height: 20rem;
padding-top: 10rem;
}
.progress-container {
height: 10rem;
width: 100%;
text-align: center;
background-color: var(--kt-5);
border: 1px solid var(--g6);
border-radius: 5rem;
overflow: hidden;
}
.progress-bar {
height: 8rem;
padding-top: 1rem;
padding-right: 3rem;
overflow: hidden;
text-align: right;
background-color: var(--c1);
border: 2rem solid #000;
border-radius: 3rem;
}
.progress-bar span { color: #000;}
/* - - - - - - - - - - - - - - - / PROGRESS */

/* - - - - - - - - - - - - - - SWITCH */
.switch { display: block; position: relative }

.switch input {
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
border: 1rem solid var(--c4);
border-radius: 7rem;
cursor: pointer;
}

input+.swSlider,
.swTxt {
width: calc(50% - 3rem);
height: 30rem;
position: absolute;
top: 3rem;
left: 3rem;
pointer-events: none;
}
input+.swSlider {
height: calc(100% - 6rem);
border-radius: 4rem;
transition: left 75ms ease-in-out;
pointer-events: none;
}
input:checked+.swSlider { left: 50% }

.swTxt {
text-align: center;
font-size: 16rem;
font-weight: 500;
color: var(--c4-l);
transition: all 150ms ease-in-out;
}
.swTxt.txt-i { left: 50% }

.switch-nor { height: 36rem }
.switch-nor input+.swSlider, .swTxt { height: 30rem }
.switch-nor .swTxt { line-height: 30rem }

.switch-1st input {
border-color: var(--c4-l);
background-color: transparent;
}
.switch-1st input+.swSlider {
background-color: var(--c4);
transition: all 150ms ease-in-out;
}
.switch-1st input~.txt-o, .switch-1st input:checked~.txt-i { color: #fff }
.switch-1st input:checked~.txt-o, .switch-1st input~.txt-i { color: var(--c4-l) }

/* - - - - - - - - SWITCH IO */
.switch-io input+.swSlider { background-color: var(--c7) }
.switch-io input:checked+.swSlider { background-color: var(--c1) }
.switch-io input~.txt-o { color: #fff }
.switch-io input~.txt-i { color: var(--c4-l) }
.switch-io input:checked~.txt-i { color: #000 }
.switch-io input:checked~.txt-o { color: var(--c4-l) }
.switch-g.switch-io input { background-color: #eee }
.switch-g.switch-io input~.txt-o, .switch-g.switch-io input:checked~.txt-i { color: #fff }
.switch-g.switch-io input:checked~.txt-o, .switch-g.switch-io input~.txt-i { color: #999 }
.switch-1st.swt, .switch-io.swt input { background-color: transparent }
/* - - - - - - - - / SWITCH IO */

.switch.disabled input {
border-color: var(--c4);
pointer-events: none;
}
.switch.disabled input+.swSlider { background-color: var(--c4) }

.switch input:checked~.txt-i { color: #fff }
/* - - - - - - - - - - - - - - SWITCH */

/* - - - - - - - - - - - - - - - LABEL */
.label-lg { font-size: 14rem; line-height: 40rem }
/* - - - - - - - - - - - - - - - LABEL */

.invalid {
height: 30rem;
overflow: hidden;
overflow-y: auto;
}
.invalid p {
font-size: 10rem;
line-height: 12rem;
color: var(--gc);
float: left;
width: 50%;
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / FORM */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  GRID */
.full { width: 100%; height: 100% }
.full-h { height: 100% }
.min-h { min-height: 1rem }

.col {
min-height: 1rem;
position: relative;
float: left;
}

.full-w,
.col-12 { width: 100% }
.col-10 { width: 83.3333% }
.col-9 { width: 75% }
.col-8 { width: 66.6666% }
.col-7 { width: 58.3333% }
.col-6 { width: 50% }
.col-5 { width: 41.6666% }
.col-45 { width: 37.5% }
.col-4 { width: 33.3333% }
.col-3 { width: 25% }
.col-2 { width: 16.6666% }
.col-15 { width: 12.5% }
.col-1 { width: 8.3333% }

.col-9-1 { width: 90% }
.col-7-1 { width: 70% }
.col-65-1 { width: 65% }
.col-6-1 { width: 60% }
.col-55-1 { width: 55% }
.col-5-1 { width: 50% }
.col-45-1 { width: 45% }
.col-42-1 { width: 42.5% }
.col-4-1 { width: 40% }
.col-3-1 { width: 30% }
.col-27-1 { width: 27% }
.col-23-1 { width: 23% }
.col-2-1 { width: 20% }
.col-15-1 { width: 15% }
.col-1-1 { width: 10% }
.col-05-1 { width: 5% }

.col-icon { width: 50rem }
.col-icon-sm { width: 41rem }
.col-icon-sm-r { width: calc(100% - 41rem) }

.col-btn2-1 { width: 44rem }
.col-btn2-11 { width: calc(100% - 44rem) }

.of-y {
overflow: hidden;
overflow-y: auto;
padding-right: 6rem;
}
.of-y2 {
overflow: hidden;
overflow-y: auto;
}

.of-x,
.of-x2 {
overflow: hidden;
overflow-x: auto;
}
.of-fade {
position: absolute;
background: linear-gradient(to right, rgba(24, 26, 26, 0) 0%, rgba(24, 26, 26, .95) 100%);
pointer-events: none;
}
.of-fade.fade-x {
width: 70rem;
height: 100%;
top: 0;
right: 0;
}

.mt-05 { margin-top: 5rem}

.mr-1 { margin-right: 10rem }
.mr-05 { margin-right: 5rem }

.mb-4 { margin-bottom: 40rem }
.mb-3 { margin-bottom: 30rem }
.mb-25 { margin-bottom: 25rem }
.mb-2 { margin-bottom: 20rem }
.mb-15 { margin-bottom: 15rem }
.mb-12 { margin-bottom: 12rem }
.mb-1 { margin-bottom: 10rem }
.mb-07 { margin-bottom: 7rem }
.mb-05 { margin-bottom: 5rem }
.mb-03 { margin-bottom: 3rem }
.mb-02 { margin-bottom: 2rem }

.p-2 { padding: 20rem }
.p-15 { padding: 15rem }
.p-1 { padding: 10rem }
.p-05 { padding: 5rem }
.p-03 { padding: 3rem }

.px-7 { padding-right: 70rem; padding-left: 70rem }
.px-5 { padding-right: 50rem; padding-left: 50rem }
.px-4 { padding-right: 40rem; padding-left: 40rem }
.px-3 { padding-right: 30rem; padding-left: 30rem }
.px-2 { padding-right: 20rem; padding-left: 20rem }
.px-15 { padding-right: 15rem; padding-left: 15rem }
.px-1 { padding-right: 10rem; padding-left: 10rem }
.px-05 { padding-right: 5rem; padding-left: 5rem }
.px-0 { padding-right: 0; padding-left: 0 }

.py-2 { padding-top: 20rem; padding-bottom: 20rem }
.py-15 { padding-top: 15rem; padding-bottom: 15rem }
.py-1 { padding-top: 10rem; padding-bottom: 10rem }
.py-05 { padding-top: 5rem; padding-bottom: 5rem }
.py-03 { padding-top: 3rem; padding-bottom: 3rem }
.py-02 { padding-top: 3rem; padding-bottom: 2rem }

.pt-4 { padding-top: 40rem }
.pt-3 { padding-top: 30rem }
.pt-25 { padding-top: 27rem }
.pt-2 { padding-top: 20rem }
.pt-15 { padding-top: 15rem }
.pt-1 { padding-top: 10rem }
.pt-08 { padding-top: 8rem }
.pt-07 { padding-top: 7rem }
.pt-05 { padding-top: 5rem }
.pt-02 { padding-top: 2rem }

.pt-secure { padding-top: 22rem }

.pr-4 { padding-right: 40rem }
.pr-3 { padding-right: 30rem !important }
.pr-2 { padding-right: 20rem }
.pr-15 { padding-right: 15rem }
.pr-1 { padding-right: 10rem }
.pr-07 { padding-right: 7.5rem }
.pr-05 { padding-right: 5rem }
.pr-0 { padding-right: 0 }

.pb-3 { padding-bottom: 30rem }
.pb-2 { padding-bottom: 20rem }
.pb-15 { padding-bottom: 15rem }
.pb-1 { padding-bottom: 10rem }
.pb-05 { padding-bottom: 5rem }
.pb-03 { padding-bottom: 3rem }

.pl-4 { padding-left: 40rem }
.pl-2 { padding-left: 20rem }
.pl-25 { padding-left: 25rem }
.pl-15 { padding-left: 15rem }
.pl-1 { padding-left: 10rem }
.pl-07 { padding-left: 7.5rem }
.pl-05 { padding-left: 5rem }

.bg-w { background-color: #fff }
.bg-g0 { background-color: var(--g0) }
.bg-gct { background-color: rgba(58,69,69,.5) }
.bg-k { background-color: #000 }
.bg-k7 { background-color: var(--kt-7) }
.bg-k6 { background-color: var(--kt-6) }
.bg-k5 { background-color: var(--kt-5) }
.bg-k2 { background-color: var(--kt-25) }
.bg-g1 { background-color: var(--g1) }
.bg-g3 { background-color: var(--g3) }
.bg-g6t { background-color: var(--g6t) }
.bgi-1 { background-color: var(--bgi-1) }
.bg-cir1 { background-color: var(--cir-1) }

.b-1-c4 { border: 1rem solid var(--c4-t5) }
.b-2-t { border: 3px solid transparent }
.br-g1 { border-right: 1rem solid var(--g1) }
.br-0 { border-right: none }
.bl-1-k { border-left: 1rem solid #000 }
.bb-1-g3 { border-bottom: 1rem solid var(--g3) }
.b-1-g5 { border: 1rem solid var(--g5) }
.b-10-w { border: 10rem solid #fff }

/* - - - - - - - - - - - - - - - AFILIADOS */
/* - - - - - - - - - - - - - - - / AFILIADOS */

/* - - - - - - - - - - - - - - - BONOS */
.bono-item {
margin-bottom: 5rem;
display: flex;
flex-direction: row;
flex-wrap: nowrap;
background-color: var(--kt-6);
border: 1rem solid var(--g6);
border-radius: 4rem;
}

/* - - - - - - - - - - - - - - - ACCORDION */

.accord-indicator {
width: 18rem;
height: 18rem;
position: absolute;
top: 8rem;
}
.accord-indicator { right: 4rem }
.accordion-content {
max-height: 0;
padding: 0;
overflow: hidden;
}

.tbl2-col-n .accord-indicator {
width: 21rem;
height: 21rem;
padding: 2rem;
position: relative;
display: block;
top: initial;
border: 1rem solid var(--g6);
border-radius: 50%;
}
.tbl2-col-n .accord-indicator img {
-webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
transform: rotate(-90deg);
}
.tbl2-col-n.active .accord-indicator img { transform: rotate(90deg) }
/* - - - - - - - - - - - - - - - / ACCORDION */

/* - - - - - - - - - - - - - - - FLEX */
.flex-center {
display: flex;
justify-content: center;
align-items: center;
}

.flex-x,
.flex-x-c {
display: flex;
flex-flow: row nowrap;
justify-content: space-between;
}
.flex-x-c { align-items: center }
.flex-y,
.flex-y2 {
display: flex;
flex-flow: column nowrap;
justify-content: space-around;
}
.flex-y2 { justify-content: space-between }

.flex-col {
display: flex;
flex-direction: row;
align-items: stretch;
}

.cols-4 {
display: flex;
flex-flow: row wrap;
}
.cols-4 p { width: 25% }
/* - - - - - - - - - - - - - - - / FLEX */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / GRID */



/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - HOME */
/* - - - - - - - - - - - - - - - HOME USER + HOME VISITOR */
.home-header {
width: 100%;
height: 35rem;
background: transparent url(./assets/images/bitwild-logo-w.svg) no-repeat 20rem 10rem;
background-size: auto 15rem;
}
/* - - - - - - - - - - - - - - - / HOME USER + HOME VISITOR */

/* - - - - - - - - - - - - - - - HOME USER */
/*
#home-user { display: grid; grid-template-rows: 31rem auto; }
*/
#home-user .home-header {
height: 31rem;
background-position: 15rem 10rem;
background-size: auto 13rem;
}

.bonos-casino {
width: 100%;
position: relative;
}

.bonos-casino-content { padding-left: 55rem; padding-right: 55rem; }

/* - - - - - - - - - - - - - - - BONO-TORNEO CASINO */
.bono-casino,
.torneo-casino {
width: 390rem;
height: 100%;
margin: 0 3rem;
padding-left: 5rem;
/* float: left; */
flex: 0 0 auto;
}

.bono-casino { background: rgba(0,0,0,.6) }
.torneo-casino { background: rgba(0,0,0,.75) }

.flex-torneoC {
width: 390rem;
display: grid;
grid-template-rows: 23rem auto 40rem;
}
.bc-title {
font-size: 14rem;
line-height: 25rem;
background: rgba(0,0,0,.15);
}
.bc-img {
width: 100%;
height:calc(100% - 6rem);
-o-object-fit: cover;
object-fit: cover;
}

.counter {
width: 100%;
display: flex;
flex-flow: row nowrap;
justify-content: space-between;
}
.counter .counterNum,
.counter .counterDiv {
font-size: 18rem;
font-weight: 500;
}
.counter .counterNum { color: var(--c2) }
.counter .counterNum.days { color: var(--g6) }
.counter .counterNum + span,
.counter .counterDiv + span {
display: block;
font-size: 12rem;
font-weight: 400;
color: var(--g8);
}

/* - - - - - - - - - - - - - - - / BONO-TORNEO CASINO */
/* - - - - - - - - - - - - - - - BONO CRYPTO */
.offer,
.offer-sigla {
height: 20rem;
line-height: 20rem;
font-size: 12rem;
}
.bc-coin {
width: 20rem;
height: 20rem;
display: inline-block;
}
/* - - - - - - - - - - - - - - - BONO CRYPTO */

/* ######################### HOME USER CONTAINER */
.home-user-container {
height: calc(100% - 31rem );
padding: 0 60rem 15rem 10rem;
}
.bonos-casino-container {
width: 100%;
padding: 0 3px;
display: flex;
flex-flow: row nowrap;
align-content: flex-start;
overflow: hidden;
position: relative;
overflow-x: auto;
/* -webkit-overflow-scrolling: touch; */
}
#home-user .bonos-casino-container {
height: 125rem;
padding:0;
}


#home-user .bono-casino {
height: 125rem;
width: 180rem;
min-width: 180rem;
flex: 0 0 180rem;
margin: 0 4rem 0 0;
padding: 5rem;
}
#home-user .bono-casino .bono-img {
width: 100%;
height: 96rem;
margin-bottom: 2rem;
-o-object-fit: cover;
object-fit: cover;
}

/* - - - - - - - - - - - - - - - / HOME USER */

/* - - - - - - - - - - - - - - - HOME VISITOR */
/*
header 30rem
banner
log 54rem
casino 13px
coins
crypto
*/
#home-visitor {
height: auto;
}
/* - - - - - - - - - - - - - - - HOME SLIDER */
#home-slider {
width: 100%;
height: calc( 100vw * .45 );
overflow: hidden;
overflow-x: auto;
pointer-events: none;
}

.home-slider-container {
height: 100%;
background: #000;
position: relative;
}
.home-slide {
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
-o-object-position: 100% 50%;
object-position: 100% 50%;
display: block;
position: absolute;
top: 0;
left: 100%;
transition: left .5s ease-in-out;
z-index: 1;
}
.home-slide.hs-0 { left: 0; z-index: 3; }
.home-slide.hs-1 { left: -100%; z-index: 2; }
/* - - - - - - - - - - - - - - - /HOME SLIDER */

.home-log {
width: 100%;
height: 74rem;
background: rgba(0,0,0,.8);
}

#home-visitor .bonos-casino { height: 212rem; }

#home-visitor .bono-casino,
#home-visitor .torneo-casino {
width: 82vw;
height: 212rem;
margin: 0 3rem;
padding-left: 0;
}
#home-visitor .torneo-casino .flex-torneoC {
width: 100%;
display: grid;
grid-template-rows: 25rem auto 10rem;
}
#home-visitor .bc-title {
padding: 5rem 5rem 5rem 10rem;
font-size: 16rem;
line-height: 30rem;
background: none;
}
#home-visitor .bc-img { height: 166rem; }

.flex-tc-block {
display: flex;
flex-flow: column nowrap;
justify-content: space-between;
}

#home-2 {
width: 100vw;
overflow: hidden;
}
.hc-menu {
width: 100vw;
height: 56rem;
}
.hc-menu li {
width: 20%;
height: 100%;
display: flex;
flex-flow: column nowrap;
justify-content: center;
align-items: center;
float: left;
color: var(--gc);
background-color: rgba(51, 153, 153, .4);
background-color: #234d4d;
}
.hc-menu li img {
width: 26rem;
height: 26rem;
margin-bottom: 2rem;
}
.hc-menu li p {
font-size: 8rem;
text-align: center;
text-transform: capitalize;
letter-spacing: -.05ch;
}
.hc-menu li.active { background-color: #647c2e }
.hc-menu li.active img {
-webkit-filter: brightness(0) invert(1);
filter: brightness(0) invert(1);
}
.hc-menu li.active p { color: #fff }

.hc-outer {
width: 100vw;
height: 940rem;
/* height: calc( 100% - 280rem ); */
}

.home-catalog {
width: 100vw;
/* height: 100%; */
/* height: 470rem; */
display: flex;
flex-direction: column;
flex-wrap: wrap;
align-items: flex-start;
align-content: flex-start;
overflow: hidden;
overflow-x: auto;
}
.home-catalog .hc-game {
width: 44vw;
/* height: 20%; */
height: 23.16vw;
position: relative;
}
.home-catalog img {
width: 44vw;
height: 100%;
/* height: 94rem; */
padding: 1px;
display: block;
position: relative;
pointer-events: none;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
-o-object-fit: cover;
object-fit: cover;
}
.btn-hc-info {
width: 50rem;
height: 50rem;
padding: 25rem 0 0 25rem;
position: absolute;
right: 0;
bottom: 0;
cursor: pointer;
}
.btn-hc-info img,
.btn-hc-info:hover img {
width: 22rem;
height: 22rem;
padding: 3rem;
background-color: var(--kt-7);
border-radius: 50%;
opacity: .8;
}
.btn-hc-info:active img { opacity: 1 }

.home-live {
height: 144rem;
position: relative;
overflow: hidden;
}
.home-live-content {
height: 110rem;
display: flex;
flex-flow: row nowrap;
overflow: hidden;
overflow-x: auto;
}

.hc-game-live {
height: 100%;
width: 44vw;
min-width: 44vw;
padding: 1px;
position: relative;
}
.hc-game-live video,
.hc-game-live img {
width: calc(44vw - 2px);
height: 100%;
-o-object-fit: cover;
object-fit: cover;
}
.hc-game-live .game-title {
width: 100%;
padding: 3rem;
position: absolute;
left: 0;
bottom: 0;
font-family: 'Roboto Condensed', sans-serif;
font-size: 12rem;
text-align: center;
line-height: 16rem;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
color: var(--gc);
-webkit-filter: drop-shadow(1rem 1rem 4rem #000);
filter: drop-shadow(1rem 1rem 4rem #000);
}

.home-coins {
width: 100%;
background: #000;
position: relative;
}
.home-coins-container { width: 100% }

.col-show-coin {
width: 12.5%;
position: relative;
opacity: .7;
}
.show-coin {
width: 24rem;
height: 24rem;
margin: 5rem;
display: inline-block;
}

#home-afil-container {
width: 100%;
height: 170rem;
padding: 30rem 20rem 30rem 50%;
margin: 30rem 0;
position: relative;
display: flex;
flex-flow: column nowrap;
justify-content: space-between;
background: #000 url(./assets/images/bg-home-afiliados.png) no-repeat left center;
background-size: auto 110rem;
}
.txt-afil {
padding-left: 5rem;
font-size: 14rem;
font-weight: 700;
}

#home-footer {
background-color: #000;
border-top: 1px solid var(--g3);
}
#home-footer img { pointer-events: none }
#home-footer img.providers { opacity: .5 }
#home-footer ul { list-style-type: none }

.footer-social li {
width: 30rem;
height: 30rem;
display: inline-block;
margin-left: 10rem;
}
.footer-social li:first-child { margin-left: 0 }
.footer-social li a {
width: 100%;
height: 100%;
display: block;
}
.footer-social li a img,
.footer-social li a:hover img {
display: block;
-webkit-filter: brightness(0) invert(1);
filter: brightness(0) invert(1);
transition: all .15s ease-in-out;
}
.footer-social li a:active img {
-webkit-filter: invert(94%) sepia(11%) saturate(7382%) hue-rotate(23deg) brightness(94%) contrast(96%);
filter: invert(94%) sepia(11%) saturate(7382%) hue-rotate(23deg) brightness(94%) contrast(96%);
transition: none;
}

.menu-footer {
display: flex;
flex-flow: row nowrap;
justify-content: space-between;
}
.menu-footer li,
.menu-footer li:hover {
display: block;
padding: 10rem 0 5rem 0;
color: var(--g9);
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
cursor: pointer;
transition: all .15s ease-in-out;
border-bottom: 1px solid var(--g3);
}
.menu-footer li:active {
color: var(--c1);
transition: none;
}

#home-footer .lang li {
width: 30rem;
height: 30rem;
display: inline-block;
text-align: center;
line-height: 30rem;
border: 1px solid var(--g9);
}

/* - - - - - - - - - - - - - - - / HOME VISITOR */

/* - - - - - - - - - - - - - - - HOME AFILIADOS VISITOR */
#home-afiliados-visitor {
position: relative;
background-color: #fff;
}
#ha-logo {
width: auto;
height: 30rem;
}
.ha-side-video {
width: 2.5%;
min-height: 1px;
}
.ha-center-video { width: 95%; }
#ha-main-video { height: 95vw }

#home-afiliados-visitor video {
width: 100%;
display: block;
background-color: #eceeef;
}
.ha-slider-container {
width: 100%;
}

.ha-slide {
margin: 20rem;
padding: 20rem;
display: block;
background-color: #f6f9f9;
}
.ha-slide:last-child { margin-bottom: 0 }

.ha-slide-title {
font-size: 22rem;
font-weight: 700;
color: var(--bwp-1);
}
.hs-slide-image img {
width: auto;
height: 80rem;
display: block;
margin: 10rem auto;
}

.hs-slide-caption p {
padding-bottom: 20rem;
font-size: 15rem;
color: var(--c4-k);
}

.ha-tutorial-container {
width: 100%;
/* height: 384rem; */
height: calc( 100vw + 64rem );
overflow: hidden;
overflow-x: auto;
position: relative;
}

#ha-tutorial-content {
width: 300%;
height: 100%;
position: absolute;
top: 0;
left: 0;
transition: left .25s ease-in-out;
}
#ha-tutorial-content.pos0 { left: 0 }
#ha-tutorial-content.pos1 { left: -100% }
#ha-tutorial-content.pos2 { left: -200% }
.ha-tutorial{
width: 33.3333%;
height: 100vw;
display: block;
float: left;
}
.ha-tutorial-control,
.ha-tutorial-control:hover {
width: 25%;
height: 100%;
position: absolute;
background: transparent url(./assets/images/icon-ctrl-l.svg) no-repeat left center;
background-size: 22.6rem 17.4rem;
top: 0;
}
.ha-tutorial-control-L { left: 0 }
.ha-tutorial-control-R { right: 0;transform: scaleX(-1); }
.ha-tutorial-control:active {
background-image: url(./assets/images/icon-ctrl-l-active.svg);
}
.dot-indicator-container {
width: 16rem;
height: 16rem;
padding: 4rem;
display: inline-block;
}
.dot-indicators {
width: 100%;
height: 20rem;
position: absolute;
left: 0;
bottom: 44rem;
text-align: center;
}
.dot-indicator {
width: 8rem;
height: 8rem;
border: 1px solid var(--bwp-1);
border-radius: 4rem;
}
.dot-indicator.active { background-color: var(--bwp-1) }

.ha-intro .content{
background-color: #eceeef;
padding: 20rem 30rem;
}
.ha-intro h2 { font-size: 21rem }
.ha-intro h1 { font-size: 30rem }
.ha-intro p {
font-size: 14rem;
font-weight: 500;
}
.ha-intro p strong {
font-weight: 700;
color: #000;
}
.portfolio-coin {
width: 12.5%;
height: 29rem;
padding-top: 5rem;
display: block;
float: left;
}
.portfolio-coin img {
width: 24rem;
height: 24rem;
display: inline-block;
}
.ha-divider {
height: 2rem;
background-color: #8fbdf8;
}

.sellos { background-color: #eceeef; }
.sellos img{
width: auto;
height: 60rem;
display: inline-block;
margin: 0 12rem;
}
#home-afiliados-visitor #home-footer {
background-color: #fff;
border-top: none;
}
#home-afiliados-visitor .footer-social li a img,
#home-afiliados-visitor .footer-social li a:hover img {
-webkit-filter: invert(40%) sepia(100%) saturate(2718%) hue-rotate(199deg) brightness(96%) contrast(97%);
filter: invert(40%) sepia(100%) saturate(2718%) hue-rotate(199deg) brightness(96%) contrast(97%);
}
#home-afiliados-visitor .footer-social li a:active img {
-webkit-filter: none;
filter: none;
}

#home-afiliados-visitor .menu-footer li,
#home-afiliados-visitor .menu-footer li:hover {
color: var(--c4-k);
border-bottom: 1px solid var(--bwp-2);
}
#home-afiliados-visitor #home-footer img.providers {
opacity: .7;
}
/* - - - - - - - - - - - - - - - / HOME AFILIADOS VISITOR */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / HOME

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - CIRCUITO */
#circuit-container {
width: 100%;
height: 100%;
position: fixed;
left: 0;
bottom: -100%;
color: #fff;
background-color: #fff;
z-index: 90900;
overflow: hidden;
overflow-y: auto;
transition: all .25s ease-in-out;
scroll-behavior: smooth;
}
#circuit-container.active { bottom: 0 }

.circuit-header {
width: 100%;
/* height: 97rem; */
background-color: var(--g3);
border-bottom: 4rem solid #fff;
}
.header-top {
background: #181a1a;
background: linear-gradient(to bottom, #181a1a 0%,#333636 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#181a1a', endColorstr='#333636',GradientType=0 );
}
#mr-ww-logo {
width: auto;
height: 50rem;
}

.circuit-map-container { position: relative }
.circuit-map-container,
#ww-map {
width: 100%;
height: auto;
display: block
}

#mr-ww {
width: 28.85%;
height: auto;
position: absolute;
left:46%; top:.9%;
transition: all .75s ease;
-webkit-filter: drop-shadow(0 4px 8px #000);
        filter: drop-shadow(0 4px 8px #000);
}

.node {
width: 33%;
height: auto;
position: absolute;
transform: translateX(-50%) translateY(-50%);
-webkit-filter: drop-shadow(0 4px 6px rgba(0,0,0,.75));
        filter: drop-shadow(0 4px 6px rgba(0,0,0,.75));
}
.node-4 { left:84.5%; top:18% }
.node-6 { left:19%; top:22% }
.node-11 { left:84.5%; top:37% }
.node-15 { left:17.5%; top:49% }
.node-18 { left:85%; top:55.25% }
.node-21 { left:25%; top:63.5% }
.node-25 { left:65%; top:77% }
.node-28 { left:54%; top:88.25% }
.node-30 { left:50%; top:96.25% }

.star {
width: 11.5%;
height: auto;
position: absolute;
cursor: pointer;
pointer-events: all;
}
.star-1 { left:35%; top:6.7% }
.star-4 { left:70.5%; top:15.5% }
.star-6 { left:27%; top:20% }
.star-11 { left:78%; top:32.9% }
.star-15 { left:9%; top:45% }
.star-18 { left:70.25%; top:51.5% }
.star-21 { left:31%; top:61% }
.star-25 { left:70.5%; top:74.35% }
.star-28 { left:38%; top:84.5% }
.star-30 { left:32.5%; top:94% }

.btn-node {
width: 40rem;
height: 40rem;
position: absolute;
background-color: red;
transform: translateX(26rem) translateY(85rem);
opacity: 0;
cursor: pointer;
}

#mr-ww.node1, .btn-node-1 { left:26.5%; top:0.5% }
#mr-ww.node2, .btn-node-2 { left:45.5%; top:2.6% }
#mr-ww.node3, .btn-node-3 { left:62.25%; top:4.7% }
#mr-ww.node4, .btn-node-4 { left:61.7%; top:9.25% }
#mr-ww.node6, .btn-node-5 { left:18.5%; top:13.8% }
#mr-ww.node5, .btn-node-6 { left:40.5%; top:11.8% }
#mr-ww.node7, .btn-node-7 { left:9.5%; top:19.7% }
#mr-ww.node8, .btn-node-8 { left:24%; top:21.6% }
#mr-ww.node9, .btn-node-9 { left:38.9%; top:22.75% }
#mr-ww.node10, .btn-node-10 { left:54.9%; top:24.2% }
#mr-ww.node11, .btn-node-11 { left:69.5%; top:26.7% }
#mr-ww.node12, .btn-node-12 { left:42.5%; top:32.8% }
#mr-ww.node13, .btn-node-13 { left:23.9%; top:33.8% }
#mr-ww.node14, .btn-node-14 { left:6.3%; top:35.4% }
#mr-ww.node15, .btn-node-15 { left:0.3%; top:38.8% }
#mr-ww.node16, .btn-node-16 { left:28.2%; top:42.3% }
#mr-ww.node17, .btn-node-17 { left:45.5%; top:43.2% }
#mr-ww.node18, .btn-node-18 { left:61.4%; top:45.2% }
#mr-ww.node19, .btn-node-19 { left:58.3%; top:51.8% }
#mr-ww.node20, .btn-node-20 { left:38.2%; top:53.65% }
#mr-ww.node21, .btn-node-21 { left:22.3%; top:54.8% }
#mr-ww.node22, .btn-node-22 { left:3.9%; top:61.85% }
#mr-ww.node23, .btn-node-23 { left:18.9%; top:64.8% }
#mr-ww.node24, .btn-node-24 { left:36.4%; top:66.25% }
#mr-ww.node25, .btn-node-25 { left:61.5%; top:68.1% }
#mr-ww.node26, .btn-node-26 { left:69.85%; top:72.75% }
#mr-ww.node27, .btn-node-27 { left:50.8%; top:75.95% }
#mr-ww.node28, .btn-node-28 { left:29.3%; top:78.25% }
#mr-ww.node29, .btn-node-29 { left:18%; top:83% }
#mr-ww.node30, .btn-node-30 { left:23.6%; top:87.75% }

.ww-footer {
height: 30rem;
position: relative;
}

#w-cover-ww-terms.w-cover { z-index: 960100 }
#w-ww-terms { padding-bottom: 40rem }
#w-ww-terms .container {
overflow: hidden;
overflow-y: auto;
}

.w-node {
width: auto;
height: 150rem;
margin: 10rem auto;
display: block;
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / CIRCUITO */

/* - - - - - - - - - - - - - - - SECTION */
section {
height: 100%;
padding-top: 10rem;
padding-right: 50rem;
padding-bottom: 10rem;
padding-left: 10rem;
}

section.wider {
height: 100%;
padding-top: 30rem;
padding-right: 0;
padding-bottom: 0;
padding-left: 0;
}

section.tabbed { padding-top: 30rem }


/* SECTION HEADER */
.section-header {
width: 100%;
height: 30rem;
position: absolute;
left: 0;
top: 0;
}

.col-section-icon {
padding: 4rem 2rem;
width: 24rem;
height: 100%;
}

.col-section-icon img,
.section-header p img {
width: 20rem;
height: 20rem;
border: 1rem solid var(--c1);
border-radius: 50%;
}

.col-section-side {
width: calc(50% - 12rem);
font-size: 11rem;
line-height: 30rem;
}

.col-section-side:first-child {
background: transparent url(./assets/images/bitwild-logo-w.svg) no-repeat 10rem center;
background-size: auto 12rem;
}
.section-header p img { vertical-align: middle }

section#wallet {
padding-top: 40rem;
padding-bottom: 0;
}

section#verificacion { padding: 40rem 0 0 0 }

.section-header .btn-filter {
position: absolute;
right: calc(70rem + env(safe-area-inset-right));
top: 8rem;
}

.cta-container-2 {
height: 100%;
padding-bottom: 66rem;
}

.legal-container-0 { height: calc(100% - 16rem) }
.tab-container-afiliados-0 { height: calc(100% - 24rem) }

.accepted-coins-container {
width: 360rem;
height: 74rem;
/* padding-bottom: 37rem; */
position: relative;
z-index: 1;
}
#home-game-info .accepted-coins-container {
width: 100%;
height: initial;
}

/* - - - - - - - - - - - - - - - WALLET */

.wallet-item {
margin-bottom: 5rem;
display: flex;
flex-direction: row;
flex-wrap: nowrap;
background-color: var(--kt-6);
border: 1rem solid var(--g6);
border-radius: 4rem;
}

.wallet-coin {
width: auto;
height: 34rem;
}

/* - - - - - - - - - - - - - - - / WALLET */

/* - - - - - - - - - - - - - - - BONOS */
.bono-item {
margin-bottom: 5rem;
display: flex;
flex-direction: row;
flex-wrap: nowrap;
background-color: var(--kt-6);
border: 1rem solid var(--g6);
border-radius: 4rem;
}

/* - - - - - - - - - - - - - - - / BONOS */

/* - - - - - - - - - - - - - - - CUENTA && VERIFICACION */
.col-verif-L,
.col-verif-R {
height: 100%
}

#menu-datos,
#menu-verif {
height: 100%;
display: flex;
flex-direction: column;
}

#menu-datos li,
#menu-verif li {
padding-right: 10rem;
display: flex;
flex: 1 1 auto;
font-size: 11rem;
color: var(--c4-l);
background-color: var(--c4-t7);
}
#menu-datos li {
padding-right: 5rem;
padding-left: 10rem;
flex-direction: column;
justify-content: center;
}

#menu-datos li.active,
#menu-verif li.active {
color: #fff;
background-color: transparent;
}

/* - - - - - - - - - - - - - - - / CUENTA && VERIFICACION */
/* - - - - - - - - - - - - - - - CUENTA */

#cuenta .flex-col { min-height: 130rem }

/* - - - - - - - - - - - - - - - / CUENTA */

/* - - - - - - - - - - - - - - - VERIFICACIÓN */
.verificacion-container { height: 100% }
.col-verif-L { width: 140rem }
.col-verif-R {
width: calc(100% - 140rem);
padding: 0 60rem 8rem 20rem;
}
.flex-y-alt { display: initial }

#menu-verif li {
padding-left: 10rem;
flex-direction: row;
align-items: center;
position: relative;
}

#menu-verif li img {
width: auto;
min-width: 30rem;
height: 30rem;
flex-grow: 1;
background-color: #0e252b;
border-radius: 50%;
}

#menu-verif li.active img { border: 1rem solid #fff }

#menu-verif li .indicator { position: relative }


/* #menu-verif li .indicator.por_subir::after (default) */
#menu-verif li .indicator::after {
width: 12rem;
height: 12rem;
display: block;
position: absolute;
right: 1rem;
top: -3rem;
content: '';
background: var(--c2) url(./assets/images/icon-verif-n.svg) no-repeat center;
background-size: contain;
border: 1rem solid #000;
border-radius: 50%;
z-index: 10;
}

#menu-verif li .indicator.verificando::after {
background: var(--c4-l) url(./assets/images/icon-verif-ng.svg) no-repeat center;
}

#menu-verif li .indicator.verificado::after {
background: var(--c1) url(./assets/images/icon-verif-y.svg) no-repeat center;
}

#menu-verif li .indicator.rechazado::after {
background: var(--c7-l) url(./assets/images/icon-verif-x.svg) no-repeat center;
}


#verificaciones h1 {
font-size: 14rem;
color: var(--c1);
}
#verificaciones p {
font-size: 11rem;
color: #fff;
}

.doc-container {
width: 100%;
height: calc(100% - 53rem);
position: relative;
background-color: #000;
padding: 5rem;
text-align: center;
}

/* - - - - - - - - - - - - - - - / VERIFICACIÓN */

/* - - - - - - - - - - - - - - - ARTICLE */
article { padding: 10rem }

.art-title {
font-size: 14rem;
color: var(--gc);
}

.back-container-1 {
width: 100%;
height: 110rem;
padding-top: 15rem;
display: flex;
flex-flow: column wrap;
overflow: hidden;
overflow-x: auto;
align-content: flex-start;
}

.balance {
width: 160rem;
margin-right: 10rem;
position: relative;
}

.balance.empty {
width: 70rem;
min-height: 15rem;
}

.balance-amount,
.balance-bono {
border: 1rem solid var(--g1);
background-color: var(--g1);
}
.balance-amount { border-bottom: 1px solid var(--g3) }
.balance-bono { border-top: none }
.selected .balance-amount,
.selected .balance-bono { border-color: var(--c1) }
.selected .balance-amount { border-bottom: 1px solid var(--g3) }
.selected .balance-bono { border-top: none }

.balance-amount p { width: 100% }

.balance-amount p .balance-coin-name {
display: inline-block;
width: calc(100% - 25rem);
transform: translateY(3rem);
}

.balance-bono { padding: 5rem 6rem 5rem 10rem }

article p { font-size: 14rem }

.balance-bono p {
height: 25rem;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
}

.balance .balance-coin {
display: inline-block;
width: 20rem;
height: 20rem;
vertical-align: middle;
}

.balance-coin p { width: 100% }

/* - - - - - - - - - - - - - - - TABS */
.tabs {
height: 36rem;
padding-top: 6rem;
padding-left: 10rem;
border-bottom: 1rem solid var(--c4);
}

.tabs li {
width: calc(25% - 5rem);
height: 30rem;
margin-left: 5rem;
display: inline-block;
font-size: 13rem;
line-height: 30rem;
color: var(--c4-l);
border-radius: 4rem 4rem 0 0;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.tabs li.active {
color: #fff;
background-color: rgba(0,0,0,.5);
border: 1rem solid var(--c4-m);
border-bottom: 1rem solid rgba(0,0,0,.75);
}

/* - - - - - - - - - - - - - - - / TABS */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  / GRID */

#ticker {
width: 100%;
position: relative;
overflow: hidden;
}

.ticker-inner { height: 14rem }

@-webkit-keyframes anim-ticker {
0% { transform: translateX(25%); }
60% { transform: translateX(-100%); }
60.0001% { transform: translateX(100%); }
100% { transform: translateX(25.01%); }
}

@keyframes anim-ticker {
0% { transform: translateX(25%); }
60% { transform: translateX(-100%); }
60.0001% { transform: translateX(100%); }
100% { transform: translateX(25.01%); }
}

.ticker-container {
height: 14rem;
display: inline-block;
padding-top: 0;
white-space: nowrap;
-webkit-animation-name: anim-ticker;
animation-name: anim-ticker;
-webkit-animation-duration: 20s;
animation-duration: 20s;
-webkit-animation-timing-function: linear;
animation-timing-function: linear;
-webkit-animation-iteration-count: infinite;
animation-iteration-count: infinite;
/* padding-left: 50%; border: 1px dashed red; */
}

#ticker .msg {
padding-right: 5rem;
padding-left: 5rem;
display: inline-block;
font-size: 8rem;
line-height: 1;
letter-spacing: .1ch;
transform: translateY(-3rem);
color: #fff;
}
#ticker .msg b {
font-weight: 400;
color: var(--c1);
}
#ticker .msg::before,
#ticker .msg::after {
color: var(--c4-l)
}
#ticker .msg::before {
margin-right: 5rem;
content: '<';
}
#ticker .msg::after {
margin-left: 5rem;
content: '>';
}

#lang-selector {
position: absolute;
top: calc(4rem);
right: calc(4rem);
color: #333;
z-index: 100;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - WINDOW */
.w-cover {
width: 100%;
height: calc(100%);
position: fixed;
left: 0;
top: 0;
background-color: var(--g7t);
z-index: 91000;
overflow: hidden;
overflow-y: auto;
}

#wc-catalog.w-cover {
display: flex;
justify-content: center;
align-items: center;
background-color: transparent;
cursor: default;
}
#wcHelp.w-cover { width: 100vw }

.window {
width: 570rem;
max-width: 96%;
height: 230rem;
min-height: 230rem;
margin: 10rem auto 0 auto;
padding-bottom: 56rem;
position: relative;
background-color: var(--g1);
}
#game-info.window { height: 250rem }

.w-title {
font-size: 14rem;
text-align: center;
color: #fff;
}

.w-actions {
width: 100%;
position: absolute;
left: 0;
bottom: 10rem;
}

#filter-catalog {
height: auto;
min-height: 0;
background-color: rgba(26, 26, 26, .95);
}

#game-info {
padding-bottom: 15rem;
background-image: none;
}

#login { padding-bottom: 0 }

#wallet-deposit { padding-bottom: 20rem }
#wallet-deposit-1,
#wallet-deposit-2 {
height: initial;
min-height: initial;
padding-bottom: 10rem;
background-image: none;
}
#wallet-deposit-2 {
margin-top: 3rem;
}
#home-conversion-coin {
background-image: none;
}

#nodo-info { padding-bottom: 15rem }

#add-coin { padding-bottom: 20rem }

#bono-activate-0,
#bono-activate-1 {
padding-bottom: 70rem
}

#reglas { padding-bottom: 20rem }

#terminos-condiciones {
min-width: 90%;
height: calc(100% - 20rem);
padding-bottom: 0;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / WINDOW */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - COIN ADD */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - COIN SELECT (BONO) */
.add-coin-container,
.dev-coin-container {
display: flex;
flex-direction: column;
flex-wrap: wrap;
align-content: flex-start;
}
.add-coin-container { height: 145rem }

.dev-coin-container { height: 55rem }
.add-coin-container.conv1 {
width: 100%;
height: 360rem;
display: block;
flex-direction: initial;
flex-wrap: initial;
align-content: initial;
}

#add-coin .coin,
#home-conversion-coin .coin,
.dev-coin-container-2 .coin {
height: 40rem;
margin: 3rem;
border: 1rem solid var(--g6);
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

#add-coin .coin { width: calc(25% - 6rem) }
#home-conversion-coin .coin {
width: calc(50% - 6rem);
display: inline-block;
}

#add-coin .coin.empty,
#home-conversion-coin .coin.empty {
border: none
}

#home-conversion-coin .coin.selected,
#add-coin .coin.selected {
border-color: var(--c1);
background-color: var(--c1-t2);
}

#add-coin .coin.disabled {
opacity: .25;
pointer-events: none;
}

#add-coin .coin img,
#home-conversion-coin .coin img {
width: 28rem;
height: 28rem;
}

#add-coin .coin-desc,
#home-conversion-coin .coin-desc {
width: calc(100% - 28rem);
padding-left: 7rem;
}

#add-coin .coin-desc p,
#home-conversion-coin .coin-desc p {
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
font-size: 12rem;
}


#wallet-deposit .coin-icon,
#wallet-deposit-1 .coin-icon,
#wallet-withdraw .coin-icon {
width: auto;
height: 44rem;
margin-top: -2rem;
}
#wallet-deposit-1 .coin-name {
display: flex;
flex-flow: row nowrap;
}
#wallet-deposit-1 .coin-name img {
width: 36rem;
height: 36rem;
}
#wallet-deposit-1 .coin-name span {
width: calc(100% - 36rem);
padding-left: 10rem;
padding-right: 5rem;
}


/* - - - - - - - - - - - - - - - - TURN */
#turn {
width: 100%;
height: 100%;
display: block;
position: absolute;
left: 0;
top: 0;
background-color: #030805;
z-index: 100000;
/* background-color: var(--cbg); */
}
body.landscape-turn #turn { display: none }

#turn-logo {
width: auto;
height: 20px;
margin: 20px;
}
.turn-icon {
width: 160rem;
height: 160rem;
margin: -80rem 0 0 -80rem;
position: fixed;
left: 50%;
top: 50%;
}
#turn-arrow {
-webkit-animation: rotateArr 1.5s ease-in-out infinite;
animation: rotateArr 1.5s ease-in-out infinite;
}
#turn-device {
-webkit-animation: rotateDev 1.5s ease-in-out infinite;
animation: rotateDev 1.5s ease-in-out infinite
}

@-webkit-keyframes rotateArr { to { transform: rotate(-180deg) } }
@keyframes rotateArr { to { transform: rotate(-180deg) } }

@-webkit-keyframes rotateDev {
0% { transform: rotate(90deg); }
10% { transform: rotate(90deg); }
60% { transform: rotate(0); }
100% { transform: rotate(0); }
}

@keyframes rotateDev {
0% { transform: rotate(90deg); }
10% { transform: rotate(90deg); }
60% { transform: rotate(0); }
100% { transform: rotate(0); }
}

/* - - - - - - - - - - - - - - - - / TURN */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - MAIN MENU */
#main-menu-cover {
width: 100%;
height: 100%;
position: absolute;
top: 0;
right: -100%;
background-color: var(--g6t);
overflow: hidden;
z-index: 80000 !important;
transition: all .25s ease-in-out;
-webkit-backdrop-filter: blur(1.5px);
backdrop-filter: blur(1.5px);
}

#main-menu-cover.active { right: 0 }

#main-menu {
width: 80%;
height: 100%;
display: flex;
flex-direction: row;
align-items: stretch;
position: absolute;
top: 0;
right: 0;
background-color: var(--g1);
}

#main-menu .copy {
width: calc(100% - 20rem );
/* padding-right: 15rem; */
position: absolute;
left: 10rem;
bottom: 10rem;
font-size: 9rem;
}

#main-menu li {
position: relative;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.menu1 {
height: 100%;
display: flex;
flex-direction: column;
}

.menu1 li,
.menu1 li:hover {
height: 14.2857%;
padding-left: 15rem;
display: flex;
flex-direction: column;
justify-content: center;
font-size: 12rem;
color: var(--c1);
/* height: 12.5%; height: 11.1111%; background-color: var(--g3); */
}

.menu1 li.li-sm { font-family: 'Roboto Condensed', sans-serif }

.menu1 li.empty { flex-grow: 2 }

.menu1 li.active,
.menu1 li:active {
color: #fff;
background-color: var(--g1);
}

.menu1 li.li-4th { color: var(--c4-l) }

.notificacions {
width: 22rem;
margin-top: -7rem;
display: block;
position: absolute;
right: 5rem;
top: 50%;
font-size: 12rem;
font-weight: 700;
line-height: 14rem;
color: #fff;
background-color: var(--c7);
border: 1rem solid #000;
border-radius: 3rem;
pointer-events: none;
}

#notificacions.notificacions {
margin-top: 0;
display: none;
right: -5rem;
top: -7rem;
font-size: 11rem;
line-height: 13rem;
}
#notificacions.notificacions.active { display: block }
#notificacions.notificacions.out { visibility: hidden }

.menu2 {
height: 100%;
padding: 0;
display: flex;
flex-direction: column;
justify-content: center;
}

.menu2 li,
.menu2 li:hover {
padding: 15rem 0 15rem 15rem;
font-size: 12rem;
}
#m2Cuenta.menu2 li,
#m2Cuenta.menu2 li:hover {
padding: 10rem 0 10rem 15rem;
}
.menu2-5 li { 	padding: 11rem 0 11rem 20rem }
.menu2 li .m-icon {
width: 28rem;
height: 28rem;
margin: 0 10rem 0 0;
display: inline-block;
vertical-align: middle;
background-color: var(--g3);
border: 1rem solid var(--c1);
border-radius: 50%;
transform: translateY(-2rem);
}
.menu2 li:active { color: var(--c1) }
.menu-selected {
color: var(--c2) !important;
background-color: var(--c1-t1);
}

/* - - - - - - - - - - - - - - - TICKETS */
.tickets,
.tickets-container {
display: block;
height: 100%;
}
.tickets-container { padding: 15rem 50rem 20rem 15rem }
.tickets-list { height: calc(100% - 20rem) }
.ticket {
min-height: 40rem;
margin-bottom: 10rem;
padding: 10rem 40rem 10rem 10rem;
font-size: 12rem;
color: var(--gc);
border: 1rem solid var(--g6);
background: transparent url(./assets/images/icon-alert-c.svg) no-repeat right top;
background-size: 40rem;
}

#playground-container {
width: calc(100% - 48rem );
height: 100%;
position: relative;
}
#game {
position: absolute;
top: 0;
left: 0;
display: block;
/* object-fit: contain; */
background-color: #000;
}

/* #cover-back { pointer-events: none; } */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - CATALOG & BACK */
section#catalog,
section#back,
section#exchanger {
width: 100%;
height: 100%;
padding: 0;
position: absolute;
top: 0;
transition: all .25s ease-in-out;
-webkit-backdrop-filter: blur(1.5px);
backdrop-filter: blur(1.5px);
}

section#catalog {
left: 100%;
z-index: 79000;
}
section#back {
left: 100%;
z-index: 78900;
}

section#catalog.active,
section#back.active {
left: 0;
}

#catalog-container-0 {
width: 100%;
height: 100%;
margin-left: 0;
background-color: var(--g1)
}
/* #catalog.active #catalog-container-0 { margin-left: env(safe-area-inset-left) } */
#back-container-0 {
width: 84%;
height: 100%;
margin-left: calc( 16% - 42rem );
margin-right: 42rem;
padding-left: 20rem;
background-color: rgba(0, 0, 0, .875);
-webkit-backdrop-filter: blur(1.5px);
backdrop-filter: blur(1.5px);
transition: all .25s ease-in-out;
/* margin-right: 0; */
}
/* section#back.active #back-container-0 { margin-right: 16% } */

.back-balances-container {
width: 100%;
/* height: calc(100% - 26rem); */
height: 100%;
display: flex;
flex-flow: column nowrap;
justify-content: space-between;
}


.col-cat-L { width: 44rem }
.col-cat-R { width: calc(100% - 44rem)}

#catalog-menu {
width: 100%;
height: 100%;
padding: 29rem 0 1rem 1rem;
display: flex;
flex-direction: row;
flex-wrap: wrap;
background-color: var(--g1);
}
#catalog-menu li {
width: calc(100% - 4rem);
flex-grow: 1;
display: flex;
flex-flow: column nowrap;
justify-content: center;
align-items: center;
}

#catalog-menu li img {
width: 24rem;
height: 24rem;
margin-bottom: 1rem;
-o-object-fit: fill;
object-fit: fill;
text-align: center;
-webkit-filter: brightness(.35);filter: brightness(.35);
}

#catalog-menu li.active img {
-webkit-filter: brightness(0) invert(83%) sepia(99%) saturate(2286%) hue-rotate(19deg) brightness(91%) contrast(99%);
        filter: brightness(0) invert(83%) sepia(99%) saturate(2286%) hue-rotate(19deg) brightness(91%) contrast(99%);
}
.catalog-branding,
.back-branding {
height: 26rem
}
.catalog-branding { padding: 5rem 15rem 5rem 0 }
.branding-logo {
width: auto;
height: 16rem;
display: inline-block;
}

#games-container {
padding-right: 40rem;
}
.catalog-container-1 {
height: calc(100% - 24rem);
padding: 0 5rem 30rem 0;
display: flex;
flex-flow: row wrap;
align-items: stretch;
align-content: flex-start;
}

.catalog-container-1 .game {
height: -webkit-fit-content;
height: -moz-fit-content;
height: fit-content;
flex-grow: 0;
}
.game {
width: calc(25% - 2rem);
margin: 0 1rem 2rem 1rem;
position: relative;
background-color: #000;
border: 1rem solid #333;
/* padding: 1rem; */
}
.game-logo {
width: 100%;
display: block;
/* min-height: 65rem; -o-object-fit: cover; object-fit: cover; */
}
.game-title {
width: 100%;
padding: 0 3rem;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
font-size: 10rem;
text-align: center;
line-height: 16rem;
color: var(--gc);
}
.game-cover {
width: 100%;
height: 100%;
position: absolute;
left: 0;
top: 0;
background-color: var(--kt-5);
border: 1rem solid var(--c1);
cursor: pointer;
}
.btn-info,
.btn-favorite,
.btn-play {
position: absolute
}
.btn-info {
left: 8rem;
top: 25%;
}
.btn-favorite {
left: 8rem;
top: 75%;
}
.btn-play {
right: 8rem;
top: 50%
}
.btn-sm.btn-info { top: 27% }
.btn-sm.btn-favorite { top: 73% }

.live-container {
width: 100%;
display: flex;
flex-flow: row wrap;
align-items: stretch;
}
.game-live {
/* width: calc(25%); */
width: calc(33.3333%);
margin: 0;
padding: 1rem;
position: relative;
background-color: rgba(51, 153, 153, .25);
}
.game-live video,
.game-live img {
width: 100%;
display: block;
}
.game-live .game-title {
width: 100%;
padding: 4rem 8rem;
position: absolute;
left: 0;
bottom: 0;
color: #fff;
background-color: rgba(0,0,0,.25);
}
.game-live .game-title p {
width: 100%;
height: 13rem;
font-family: 'Roboto Condensed', sans-serif;
font-size: 11rem;
line-height: 1em;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
-webkit-filter: drop-shadow(1rem 1rem 4rem #000);
filter: drop-shadow(1rem 1rem 4rem #000);
}


.game .btn-play.btn-lg { margin-top: -21rem }
.game .btn-nor { 	margin-top: -15rem }
.game .btn-sm { 	margin-top: -12rem }

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / CATALOG */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - GAME INFO */
.info-title {
font-size: 18rem;
text-align: left;
color: #fff;
}

.fav-indicator {
width: 22rem;
height: 22rem;
display: inline-block;
vertical-align: middle;
border: 1px solid var(--g5);
border-radius: 50%;
}
.fav-indicator.selected {
background-color: #000;
border-color: var(--g9);
}
.fav-indicator.active { border: 1px solid var(--gc); }
.fav-indicator img {
-webkit-filter: grayscale(1) brightness(.25);
filter: grayscale(1) brightness(.25)
}
.fav-indicator.active img {
-webkit-filter: brightness(50%) sepia(100) saturate(100) hue-rotate(25deg);
filter: brightness(50%) sepia(100) saturate(100) hue-rotate(25deg)
}

.game-play {
width: 100%;
height: 100rem;
display: block;
-o-object-fit: cover;
object-fit: cover;
background-color: #000;
}
#home-game-info .game-play {
width: 100%;
height: 158rem;
}

.info-detail {
font-size: 13rem;
line-height: 20rem;
}

.info-detail .info-label {
width: 52%;
padding-right: 10rem;
display: inline-block;
text-align: right;
letter-spacing: -.025ch;
color: var(--g9);
}

.accepted-coins .coin {
width: 40rem;
padding-top: 2rem;
display: block;
float: left;
font-family: 'Roboto Condensed', sans-serif;
font-size: 10rem;
text-align: center;
text-transform: uppercase;
color: var(--c2);
}

#home-game-info .accepted-coins .coin {
width: 12.5%;
margin-bottom: 5rem;
}

.accepted-coins .coin img {
width: 18rem;
height: 18rem;
margin: 2rem auto;
display: block;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / GAME INFO */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - BACK - LAST BET */
#last-bet span { font-size: 10rem }
.pr-last-bet { padding-right: 88rem }
.last-bet-logo {
width: auto;
height: 15rem;
margin-top: 7rem;
opacity: .5;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / BACK - LAST BET */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / CATALOG & BACK */


/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - EXCHANGER */
section#exchanger {
padding-top: 50rem;
padding-bottom: 60rem;
}

#exchanger .coin {
width: 28rem;
height: 28rem;
}

#w-exchanger-cover.w-cover {
max-height: 100%;
z-index: 70000;
top: -100%;
transition: all .25s ease-in-out;
}
#w-exchanger-cover.w-cover.active { top: 0 }

#w-exchanger {
width: 100%;
/* height: 200px; */
padding: 10rem 20rem 0 20rem;
background-color: rgba(0, 0, 0, .8);
-webkit-backdrop-filter: blur(1.5px);
backdrop-filter: blur(1.5px);
}
#w-exchanger .coin {
width: 36rem;
height: 36rem;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / EXCHANGER */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RULES */

.rule {
width: 110rem;
min-width: 110rem;
border-right: 1rem solid var(--c4-t5);
border-left: 1rem solid transparent;
}

.rule .coin {
width: auto;
height: 28rem;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / RULES */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - TICKETS */
.tickets-i-container {
height: calc(100% - 14rem);
border-bottom: 1rem solid var(--g3);
}

.ticket-item {
padding-right: 5rem;
padding-left: 5rem;
/* background-color: var(--kt-5); */
border-top: 1rem solid var(--g3);
}
.ticket-item.selected { background-color: var(--bgi-1) }
.ticket-item * { pointer-events: none }

.status {
padding: 3rem 5rem;
letter-spacing: .1ch;
background-color: #000;
border: 1rem solid var(--g3);
border-radius: 3rem;
}
.status.abierto { color: var(--c1) }
.status.cerrado { color: var(--c7-l) }

.ticket-msg {
width: 90%;
margin-bottom: 10rem;
padding: 10rem;
background-color: #333;
border-radius: 6rem;
}
.ticket-msg .txt-hl { color: var(--c4-l) }
.ticket-msg.admin {
margin-left: 10%;
background-color: var(--c4);
}
.ticket-msg.client {
background-color: var(--c4-k)
}

.col-ticket-L { width: 128rem }
.col-ticket-R { width: calc(100% - 128rem) }

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / TICKETS */

#w-notify-cover.w-cover {
left: 100%;
}
#w-notify-cover.w-cover.active {
left: 0;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - TOOLTIP */
.tooltip {
padding-right: 18rem;
position: relative;
background: transparent url(./assets/images/icon-tooltip.svg) no-repeat right center;
background-size: 14rem 14rem;
cursor: pointer;
}

.tooltip.tt-c2 { background-image: url(./assets/images/icon-tooltip-c2.svg) }

.tooltip.tt-bonus { background-position: right bottom }

.tooltip.tt-table {
width: calc(100% - 10rem);
height: 100%;
padding-right: 0;
background-position: center;
background-size: 20rem 20rem;
}

.tooltip span.tt-content {
padding: 8rem;
display: none;
position: absolute;
font-size: 11rem;
text-align: initial;
color: var(--tooltxt);
background-color: var(--tooltip);
z-index: 10;
}

.tooltip.active span.tt-content { display: inline-block }

.tooltip.tt-rb span.tt-content {
max-width: 300rem;
right: 9rem;
top: calc(100% + 10rem);
border-radius: 4rem 0 4rem 4rem;
box-shadow: 0 0 8rem 4rem rgba(0, 0, 0, .5);
}

.tooltip.tt-rb span.tt-content::after {
width: 0;
height: 0;
position: absolute;
right: 0;
top: -8rem;
content: '';
border: 4rem solid transparent;
border-color: transparent var(--tooltip) var(--tooltip) transparent;
}

.tooltip.tt-lb span.tt-content {
width: 300rem;
max-width: 300rem;
right: 9rem;
top: calc(100% + 10rem);
border-radius: 4rem 0 4rem 4rem;
box-shadow: 0 0 8rem 4rem rgba(0, 0, 0, .5);
}
.tooltip.tt-lb span.tt-content::after {
width: 0;
height: 0;
position: absolute;
right: 0;
top: -8rem;
content: '';
border: 4rem solid transparent;
border-color: transparent var(--tooltip) var(--tooltip) transparent;
}

.tooltip.tt-rt span.tt-content {
max-width: 300rem;
right: 9rem;
bottom: calc(100% + 10rem);
border-radius: 4rem 4rem 0 4rem;
box-shadow: 0 0 8rem 4rem rgba(0, 0, 0, .5);
}
.tooltip.tt-rt span.tt-content::after {
width: 0;
height: 0;
position: absolute;
right: 0;
bottom: -8rem;
content: '';
border: 4rem solid transparent;
border-color: var(--tooltip) var(--tooltip) transparent transparent;
}

.tooltip.tt-ct span.tt-content {
max-width: 100%;
left: 0rem;
bottom: 40rem;
border-radius: 4rem;
box-shadow: 0 0 8rem 4rem rgba(0, 0, 0, .5);
}
.tooltip.tt-ct span.tt-content::after {
width: 0;
height: 0;
position: absolute;
right: calc( 50% - 8rem);
bottom: -15rem;
content: '';
border: 8rem solid transparent;
border-color: var(--tooltip) transparent transparent transparent;
}

/* - - - - TABLE TOOLTIP */
table .error {
width: 100%;
height: 100%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / TOOLTIP */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - TABLE */
th {
padding: 2rem 5rem 3rem 5rem;
font-size: 11rem;
font-weight: 400;
color: var(--g8);
/* border-bottom: 1rem solid var(--c1); */
}

.th-1st {
color: var(--c1);
border-bottom-color: var(--c1);
}

.th-2nd {
color: var(--c2);
border-bottom-color: var(--c2);
}

.th-4th th {
color: var(--c4-l);
border-bottom-color: var(--c4-t7);
}

.th-5th {
color: var(--c5);
border-bottom-color: var(--c5);
}

td {
height: 54rem;
padding: 5rem;
font-size: 14rem;
/* border-bottom: 1rem solid var(--c4-t5); */
}
.tbl-striped tr:nth-child(even) {
background-color: rgba(255,255,255,.075);
}

.t-icon img {
width: 28rem;
height: 28rem;
border: 1rem solid #ccc;
border-radius: 50%;
cursor: pointer;
}

.tbl-balance th{
font-size: 10rem;
font-weight: 400;
line-height: 1;
}

.tbl-balance td {
height: 14rem;
padding: 2rem 5rem;
font-size: 10rem;
}

td img { pointer-events: none }

.t-1st img,
.t-1st:hover img {
border-color: var(--c1)
}

.t-1st:active img {
background-color: var(--c1);
border-color: var(--c1);
-webkit-filter: none;
filter: none;
}

.t-4th img,
.t-4th:hover img {
border-color: #fff;
-webkit-filter: brightness(0) invert(52%) sepia(56%) saturate(567%) hue-rotate(131deg) brightness(92%) contrast(91%);
filter: brightness(0) invert(52%) sepia(56%) saturate(567%) hue-rotate(131deg) brightness(92%) contrast(91%);
}

.t-4th:active img {
background-color: var(--c4);
border-color: var(--c4);
-webkit-filter: none;
filter: none;
}

.t-7th img,
.t-7th:hover img {
border-color: #fff;
-webkit-filter: brightness(0) invert(45%) sepia(48%) saturate(1241%) hue-rotate(335deg) brightness(103%) contrast(101%);
filter: brightness(0) invert(45%) sepia(48%) saturate(1241%) hue-rotate(335deg) brightness(103%) contrast(101%);
}

.t-7th:active img {
background-color: var(--c7-l);
border-color: var(--c7-l);
-webkit-filter: none;
filter: none;
}

.t-g img,
.t-g:hover img {
border-color: #fff;
-webkit-filter: brightness(.75);
filter: brightness(.75);
}

.t-g:active img {
background-color: var(--g9);
border-color: var(--g9);
-webkit-filter: none;
filter: none;
}

.t-sm { width: 50rem }

.t-sm0 { width: 90rem }
.btn-w1 { width: 120rem }

[class*="faux"].tac { justify-content: center }

.rule [class*="faux-td"] { background-color: transparent }

.tbl-tc td {
height: 19rem;
padding: 0 0 2rem 0;
vertical-align: bottom;
font-size: 12rem;
font-weight: 400;
}
/* .tbl-tc td * { line-height: 12rem } */
.tbl-tc tr:first-child td { border-top: 1px solid var(--c4-t5) }
.tbl-tc td.ammount {
font-size: 14rem;
font-weight: 500;
}
.tbl-tc td.crypto {
padding: 0 5rem 2rem 5rem;
color: var(--c2);
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - TABLE 2 */
.table-logo {
width: auto;
height: 16rem;
}
.tbl2 th {
color: var(--g8);
border-bottom: none;
}
.tbl2 td,
.tbl2 .td { border-bottom: none }
.tbl2 .td {
width: 100%;
display: flex;
align-items: center;
justify-content: center;
}

.tbl2-nor td { height: 30rem }

.tbl2-md td,
.tbl2-md th,
.tbl2-md .td { height: 40rem }

.tbl2-lg td,
.tbl2-lg th { height: 50rem }
.tbl2-lg .td { height: 50rem }

.tbl2-sm td {
padding-left: 8rem;
padding-right: 8rem;
height: 26rem;
font-size: 12rem;
}
.tbl2-lg.tbl-vert th,
.tbl2-md.tbl-vert th {
padding-left: 8rem;
padding-right: 5rem;
font-size: 13rem;
text-align: left;
color: var(--gc);
}
.tbl2-md.tbl-vert th { font-size: 11rem }
.tbl-vert td,
.tbl-vert .td {
padding-left: 5rem;
padding-right: 5rem;
border-left: 2rem solid #000;
}
.tbl-vert td { width: 30vw }
.tbl-vert td.col-45-1 { width: 45% }
.tbl-vert td.col-3 { width: 25% }
.tbl-vert td.col-2-1 { width: 20% }

.tbl-vert td:last-child { width: initial }

.tbl2-lg th.th-2x,
.tbl2-lg td.td-2x,
.tbl2-lg .td-2x { height: 60rem }
.tbl2-md th.th-2x,
.tbl2-md td.td-2x,
.tbl2-md .td-2x { height: 60rem }

.tbl2.tbl2-striped tr:nth-child(odd) th {
background-color: rgba(51,54,54,.5);
}
.tbl2.tbl2-striped.tbl2-head {
background-color: rgba(50,250,250,.1);
}
.tbl2.tbl-vert.tbl2-striped tr th {
background-color: #273d3d;
}
.tbl2.tbl-vert.tbl2-striped tr:nth-child(odd) th {
background-color: #1b3131;
}
.tbl2-head1 th { border-left: 2rem solid #000 }
.tbl2-head1 th.tac { text-align: center }
.tbl2-head1 th:first-child { border-left: none }


.tbl2.tbl2-striped tr:nth-child(odd) td,
.tbl2.tbl2-striped .td:nth-child(odd) {
background-color: var(--g3);
}
.tbl2.tbl2-striped.striped-alt tr:nth-child(odd) td,
.tbl2.tbl2-striped.striped-alt .td:nth-child(odd) {
background-color: rgba(51,54,54,.5);
}
.tbl2-noHead.tbl2.tbl2-striped tr:nth-child(odd) td {
background-color: transparent;
}
.tbl2-noHead.tbl2.tbl-vert.tbl2-striped tr td {
background-color: transparent;
}
.tbl2-noHead.tbl2.tbl2-striped tr:nth-child(even) td {
background-color: rgba(51,54,54,.5);
}

.tbl2.tbl2-striped.tbl2-noHead.tbl-vert tr td,
.tbl2.tbl2-striped.tbl2-noHead.tbl-vert .td {
background-color: transparent;
}

.tbl2.tbl2-striped.tbl2-noHead.tbl-vert tr:nth-child(even) td,
.tbl2.tbl2-striped.tbl2-noHead.tbl-vert .td:nth-child(even) {
background-color: rgba(51,54,54,.5);
}

.accordion2-vertical .td:nth-child(even) {
background-color: rgba(195,198,198,.1) !important;
}

.tbl2-body1 td:first-child { border-left: none }


.tbl2 .hcu-coin {
width: 18rem;
height: 18rem;
transform: translateY(-1rem);
}

.tbl2 .td-icon img {
width: 36rem;
height: 36rem;
}
.tbl2 .td-icon.td-icon-7th img { -webkit-filter: brightness(0) saturate(100%) invert(44%) sepia(92%) saturate(1547%) hue-rotate(339deg) brightness(104%) contrast(102%); filter: brightness(0) saturate(100%) invert(44%) sepia(92%) saturate(1547%) hue-rotate(339deg) brightness(104%) contrast(102%) }

.tbl2.tbl2-striped .td-icon:active,
.tbl2 .td-icon:active {
background-color: var(--c1);
}
.tbl2.tbl2-striped .td-icon-7th:active,
.tbl2 .td-icon-7th:active {
background-color: var(--c7-l);
}
.tbl2 .td-icon:active img,
.tbl2 .td-icon-7th:active img { -webkit-filter: brightness(0); filter: brightness(0);}
/* .hcu-coin + .coin-name { line-height: 18rem } */

.tbl2-col-n { width: 35vw; min-width: 35vw; }
.tbl2-col-n:last-child { border-right: 2rem solid #000;}
.tbl2-col-n2 { width: 30vw; min-width: 30vw; }
.tbl2-col-empty {
/* width: 50vw;
min-width: 50vw; */
width: 100%;
min-width: 100%;
position: absolute;
right: 0;
top: 0;
pointer-events: none;
}
.faux-tbl-vert {
display: flex;
flex-flow: row nowrap;
position: relative;
overflow: hidden;
overflow-x: scroll;
}

.tbl2 .tr-c1 th { background-color: #415814 !important }
.tbl2 .tr-c1:nth-child(even) th { background-color: #4b611e !important }
.tbl2 .tr-c2 th { background-color: #66621c !important }
.tbl2 .tr-c2:nth-child(even) th { background-color: #716d26 !important }

.tbl2 tr.titlespacer,
.tbl2 .titlespacer {
height: 15rem;
background-color: transparent;
}
.tbl2-title-row {
width: 100%;
height: 30rem;
position: absolute;
text-align: center;
line-height: 30rem;
background-color: var(--g1);
}
.title-1 {
left: 0;
top: 360rem;
}
.title-2 {
left: 0;
top: 510rem;
}
.bwild-table {
width: auto;
height: 14rem;}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / TABLE 2 */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / TABLE */

/* - - - - - - - - - - - - - - - HOME AFILIADO */
section#home-afiliado {
padding-top: 50rem;
padding-right: calc(10rem + env(safe-area-inset-right));
padding-bottom: 0;
padding-left: calc(10rem + env(safe-area-inset-left));
}
#home-afiliado .section-header {
height: 50rem;
padding: 0 45rem;
}

/* - - - - - - - - - - - - - - - / HOME AFILIADO */


/* ######################### WIDGET CONTROL */
/* CON POSICIÓN FIJA */
.widget-control-area {
width: 42rem;
height: 100%;
padding: 3rem 3rem 3rem 0;
position: absolute;
right: 0;
top: 0;
background-color: #000;
z-index: 81000;
}
.widget-control {
width: 100%;
height: 100%;
padding: 3rem;
background-color: #000;
border: 1rem solid var(--g6);
border-radius: 6rem;
}
.widget-notifications {
height: 11rem;
border-radius: 2rem;
font-size: 9rem;
font-weight: 500;
text-align: center;
letter-spacing: .05rem;
color: var(--g6);
background-color: var(--g3);
}
.widget-notifications.active {
color: #fff;
background-color: var(--c7);
}
.widget-section {
width: 100%;
height: 100%;
}
.widget-section li {
height: 25%;
padding: 1.5rem 0;
cursor: pointer;
}
.widget-section li:first-child { padding-top: 0 }
.widget-section li:last-child { padding-bottom: 0 }
.widget-section li div {
height: 100%;
display: flex;
align-items: center;
justify-content: center;
background-color: var(--g3);
border-radius: 3rem;
cursor: pointer;
}
.widget-section li div,
.widget-section li div img {
transition: all .25s cubic-bezier(.39, .575, .565, 1);
}
.widget-section li div:active,
.widget-section li div.active,
.widget-section li div:active img,
.widget-section li div.active img {
transition-duration: 0s;
}
.widget-section li div:active,
.widget-section li div.active {
background-color: var(--c1);
}
.widget-section li div:active img,
.widget-section li div.active img {
-webkit-filter: brightness(0);
filter: brightness(0);
}
.widget-section li div.div2nd:active,
.widget-section li div.div2nd.active {
background-color: var(--c4);
}
.widget-section li div.div2nd:active img,
.widget-section li div.div2nd.active img {
-webkit-filter: brightness(0);
filter: brightness(0);
}
.widget-section li div.empty,
.widget-section li div.empty:active {
background-color: var(--g3);
}
/* ######################### / WIDGET CONTROL */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - CLASSES */

.op-5 { opacity: .5 }

img.icon {
width: 18rem;
height: 18rem;
display: inline-block;
margin-right: 5rem;
vertical-align: middle;
}

img.icon-lg {
width: 24rem;
height: 24rem;
display: inline-block;
margin-right: 5rem;
vertical-align: middle;
}

img.icon.icon-c2 {
-webkit-filter: invert(72%) sepia(85%) saturate(1981%) hue-rotate(338deg) brightness(101%) contrast(94%);
filter: invert(72%) sepia(85%) saturate(1981%) hue-rotate(338deg) brightness(101%) contrast(94%);
}


.curr-point { cursor: pointer }

.ilb { display: inline-block }

.fl { float: left }
.fr { float: right }
.pos-rel { position: relative }

.cb { clear: both }

.tal { text-align: left }
.tac { text-align: center }
.tar { text-align: right }
.taj { text-align: justify }

.txt-uc { text-transform: uppercase }
.txt-cap { text-transform: capitalize }

p.txt-a,
p.txt-a:hover {
display: inline-block;
font-size: 11rem;
font-weight: 400;
color: var(--c1);
text-decoration: underline;
}
p.txt-a:active { color: #fff }


.ff-nor { font-family: Roboto, sans-serif }
.ff-cnd { font-family: 'Roboto Condensed', sans-serif }

.txt-reg { font-weight: 400 }
.txt-mid { font-weight: 500 }
.txt-bol { font-weight: 700 }

.txt-xxlg { font-size: 21rem }
.txt-lg { font-size: 16rem }
.txt-xnor { font-size: 15rem }
.txt-nor { font-size: 14rem }
.txt-snor { font-size: 13rem }
.txt-sm { font-size: 12rem }
.txt-ssm { font-size: 11rem }
.txt-sssm { font-size: 10rem }
.txt-xsmm { font-size: 9.5rem !important }
.txt-xsm { font-size: 9rem !important }

.txt-w { color: #fff }
.txt-k { color: #000 }
.txt-c1 { color: var(--c1) }
.txt-c2 { color: var(--c2) }
.txt-c4 { color: var(--c4-m) }
.txt-c4-l { color: var(--c4-l) }
.txt-gc { color: var(--gc) !important }
.txt-ga { color: var(--ga) }
.txt-g9 { color: var(--g9) }
.txt-g8 { color: var(--g8) }
.txt-g6 { color: var(--g6) }
.txt-a1 { color: var(--bwp-1) }
.txt-a3 { color: var(--bwp-3) }
.txt-cir1 { color: var(--cir-1) }

.txt-lnk {
padding-bottom: 2rem;
color: var(--g9);
border-bottom: 1rem solid var(--g9);
cursor: pointer;
}
.txt-lnk:hover {
color: var(--g5);
border-bottom-color: var(--g6);
}

.lh-15 { line-height: 150% }
.lh-2 { line-height: 200% }

.txt-mx2 { letter-spacing: -.025ch }
.txt-x1 { letter-spacing: .15ch }
.txt-x2 { letter-spacing: .25ch }

.no-wrap { white-space: nowrap }
.word-wrap {
word-wrap: break-word;
overflow-wrap: anywhere;
}
.wordBreak { word-break: break-all }

.ellipsis {
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
}

.empty { background: none }

.invisible { opacity: 0; z-index: -10; }
.invisible button { pointer-events: none }

.qr-code {
display: inline-block;
min-height: 105rem;
background-color: #fff;
}

body::-webkit-scrollbar,
.noScrlBr::-webkit-scrollbar,
.of-y2::-webkit-scrollbar,
.of-x2::-webkit-scrollbar,
.faux-tbl-vert::-webkit-scrollbar,
.bonos-casino-container::-webkit-scrollbar,
#home-slider::-webkit-scrollbar,
.home-live-content::-webkit-scrollbar,
.home-catalog::-webkit-scrollbar,
.home-live::-webkit-scrollbar,
.ha-tutorial-container::-webkit-scrollbar,
#circuit-container::-webkit-scrollbar,
.back-container-1::-webkit-scrollbar,
.w-cover::-webkit-scrollbar,
.col-prices-container::-webkit-scrollbar {
display: none;
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / CLASSES */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SWALL */
/* Swal Stuff */
.swal2-container { z-index: 9999999 !important }
.swal2-popup { font-size: 1.6rem !important }
/*.swal2-container { zoom: 7 }*/
.swal2-styled:focus { box-shadow: none !important }
.swal2-popup { font-size: 13rem !important }

.swal2-popup { background-color: var(--g3) !important }
.swal2-icon.swal2-warning {
color: var(--c2) !important;
border-color: var(--c2) !important;
}
.swal2-icon.swal2-question .swal2-icon-content { color: var(--c4-l) !important }
.swal2-icon.swal2-question { border-color: var(--c4-l) !important }

.swal2-styled.swal2-confirm {
color: #000 !important;
background-color: var(--c1) !important;
}
.swal2-styled.swal2-cancel {
color: #000 !important;
background-color: var(--g6) !important;
}
.swal2-icon.swal2-success {
color: var(--c1) !important;
border-color: var(--c1) !important;
}
.swal2-content {
padding: 20rem !important;
color: var(--gc) !important;
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / SWALL */
.modal,
.modal:focus,
.modal-dialog,
.modal-dialog:focus {
outline: none !important;
box-shadow: none !important;
}
.modal-open { overflow: hidden }

}




/*
░██░░░░░░██████░░██░░░░░░██░███████░░
░██░░░░░██░░░░██░████░░░░██░██░░░░██░
░██░░░░░████████░██░░██░░██░██░░░░██░
░██░░░░░██░░░░██░██░░░░████░██░░░░██░
░██████░██░░░░██░██░░░░░░██░███████░░

*/
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - QUERY */
/* (orientation: LANSDCAPE ) */
@media (min-aspect-ratio: 13/9) {

.hidden-landscape,
.widget-control.hidden-landscape { display: none }
.hidden-portrait { display: block }
.widget-control.hidden-portrait { display: flex }

.add-coin-container.conv1 { height: 135rem }
#home-conversion-coin .coin { width: calc( 25% - 6rem );
}
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / QUERY */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - QUERY */
/* (orientation: LANSDCAPE ) */
@media only screen and (min-aspect-ratio: 13/9) and (max-width: 768px) {

.window { margin-top: 10rem }

.ff-cnd-sm { font-family: 'Roboto Condensed', sans-serif }

th { font-size: 10rem }
td { font-size: 12rem }

}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / QUERY */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - QUERY */
/* (orientation: LANSDCAPE ) */
@media only screen  and (min-aspect-ratio: 13/9) and (max-width: 900px) {
html { font-size: 1.3px }
}
/* (orientation: LANSDCAPE ) */
@media only screen and (min-aspect-ratio: 13/9) and (max-width: 768px) {
html { font-size: 1.1px }
}



/*
░███████░░░██████░░████████░░████████░
░██░░░░██░██░░░░██░██░░░░░██░░░░██░░░░
░███████░░██░░░░██░████████░░░░░██░░░░
░██░░░░░░░██░░░░██░██░░░░░██░░░░██░░░░
░██░░░░░░░░██████░░██░░░░░░██░░░██░░░░

*/
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - QUERY */
/* (orientation: PORTRAIT ) */
@media (max-aspect-ratio: 13/9) {

body {
/* height: initial; */
padding:0;
padding-top: env(safe-area-inset-top);
position: relative;
}
/* body::-webkit-scrollbar { width: 4px; 	height: 0 } */
.fullscreen-bg {
width: 100vh;
height: 100vw;
transform: rotate(90deg);
transform-origin: 50vw 50vw;
}

#main-container {
min-height: 100%;
padding-top: env(safe-area-inset-top);
}
#main-container.unboxed { height: initial }

/* - - - - - - - - - - - - - - - - TURN */
#turn { display: none }
#turn.noTurn { display: none }
#turn-logo {
width: 50%;
height: auto;
margin: 20% 25% 0 25%;
}
.turn-icon {
width: 200rem;
height: 200rem;
margin: -100rem 0 0 -100rem;
position: fixed;
left: 50%;
top: 50%;
}
#turn-arrow {
-webkit-animation: rotateArr 1.5s ease-in-out infinite;
animation: rotateArr 1.5s ease-in-out infinite
}
#turn-device {
-webkit-animation: rotateDev 1.5s ease-in-out infinite;
animation: rotateDev 1.5s ease-in-out infinite
}

@-webkit-keyframes rotateArr { to { transform: rotate(180deg) } }
@keyframes rotateArr { to { transform: rotate(180deg) } }

@-webkit-keyframes rotateDev {
0% { transform: rotate(0); }
10% { transform: rotate(0); }
60% { transform: rotate(90deg); }
100% { transform: rotate(90deg); }
}

@keyframes rotateDev {
0% { transform: rotate(0); }
10% { transform: rotate(0); }
60% { transform: rotate(90deg); }
100% { transform: rotate(90deg); }
}
body.landscape-turn #turn { display: block }
/* - - - - - - - - - - - - - - - - / TURN */

/* - - - - - - - - - - - - - - - - HOME */
.home-user-container {
height: calc(100% - 96rem );
padding: 0;
overflow: hidden;
overflow-y: auto;
}

.hcu-coin {
width: 16rem;
height: 16rem;
margin-right: 5rem;
display: inline-block;
vertical-align: middle;
}

.home-promos-container,
.home-games-container {
padding: 15rem 15rem 0 15rem;
display: flex;
flex-flow: column wrap;
justify-content: space-around;
overflow: hidden;
overflow-x: auto;
cursor: pointer;
}
.home-promos-container {
width: 100%;
height: calc(36.617vw + 40rem);
}
.home-games-container {
width: 100%;
height: calc(35vw + 45rem);
}

.home-games-container .game {
display: block;
margin: 0 15rem 15rem 0;
width: 49vw;
height: 26.666vw;
position: relative;
transition: all .2s ease-in-out;
cursor: pointer;
}
.home-games-container .game.in {
width: 65vw;
height: 35vw;
}

.home-games-container .game.in .game-cover .b-game {
width: 60rem;
height: 60rem;
}
.home-games-container .game.in .game-cover .b-game.b-play { margin-top: -30rem }

.home-promos-container img,
.home-games-container img {
display: block;
-o-object-fit: cover;
object-fit: cover;
pointer-events: all;
transition: all .2s ease-in-out;
}
.home-promos-container img {
width: 49vw;
height: 27.603vw;
margin: 0 15rem 15rem 0;
cursor: pointer;
}
.home-promos-container img.in {
width: 65vw;
height: 36.617vw;
}
.home-promos-container .img-empty {
width: calc(17.5vw - 15rem );
height: 36.617vw;
margin-bottom: 15rem;
}
.home-games-container img {
width: 100%;
height: 100%;
/* width: 49vw; height: 26.666vw; */
}
/* .home-games-container img.in { width: 65vw; height: 35vw; } */

.home-games-container .img-empty {
width: calc(17.5vw - 15rem );
height: 35vw;
margin-bottom: 15rem;
}

.home-header {
width: 100%;
height: 38rem;
background: transparent url(./assets/images/bitwild-logo-w.svg) no-repeat left center;
background-size: auto 17rem;
}
#home-user .home-header {
height: 40rem;
background-size: auto 20rem;
}
/* - - - - - - - - - - - - - - - - SECTION */
.section-header,
#home-afiliado .section-header.sh-afiliado {
height: 40rem;
padding: 0 15px;
/* background: transparent url(././assets/images/bitwild-logo-w.svg) no-repeat 15rem center; background-size: auto 20rem; */
}
.section-header.sh-afiliado {
background: transparent url(././assets/images/bitwild-partners-logo-w.svg) no-repeat 15rem center;
background-size: auto 22rem;
}
.section-title {
width: 100%;
height: 40rem;
display: flex;
flex-flow: row nowrap;
justify-content: flex-end;
align-items: center;
}
.section-icon {
width: 24rem;
height: 24rem;
border: 1px solid #fff;
border-radius: 50%;
}

.main-logo {
height: 40rem;
padding: 10rem 15rem;
display: inline-block;
position: absolute;
left: 0;
top: 0;
cursor: pointer;
}
.main-logo img {
width: auto;
height: 20rem;
}
.main-logo:active img {
-webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
}

/* - - - - - - - - - - - - - - - - GRID */

.invalid { height: 46rem }
.invalid p { float: none; width: initial; }

.window {
width: 95%;
height: initial;
margin-top: 10rem;
background-image: none;
}

.hidden-landscape { display: block }
.widget-control.hidden-landscape { display: flex }
.hidden-portrait,
.widget-control.hidden-portrait { display: none }


.ff-cnd-portrait { font-family: 'Roboto Condensed', sans-serif }

/* - - - - - - - - - - - - - - - MENU */
#main-menu-cover {
right: 0;
top: 100%;
}
#main-menu-cover.active { top: 0 }

#main-menu {
width: calc(100% - 10rem);
height: initial;
display: block;
position: absolute;
top: initial;
right: initial;
left: 5rem;
bottom: -100%;
background-color: #000;
border-radius: 20rem 20rem 0 0;
transition: all .25s ease-in-out;
}
#main-menu-cover.active #main-menu { bottom: 60rem }

.menu1, .menu2 .menu3 {
height: auto;
display: block;
}
.menu1 li,
.menu1 li:hover,
.menu2 .menu3 li,
.menu2 .menu3 li:hover {
width: calc(33.333% - 10rem);
padding: 15rem 0 10rem 0;
padding-left: initial;
margin: 5rem;
display: inline-block;
font-size: 11rem;
text-align: center;
color: var(--g9);
background-color: var(--g1);
border: 1px solid transparent;
}
.menu2 .menu3 li,
.menu2 .menu3 li:hover {
height: auto;
border-radius: 0;
}
.menu1 li img,
.menu1 li:hover img,
.menu2 .menu3 li img,
.menu2 .menu3 li:hover img {
width: 47px;
height: 47px;
margin: 0 auto 12rem auto;
display: block;
-webkit-filter: brightness(.5);
filter: brightness(.5);
}
.menu1 li:active,
.menu2 .menu3 li:active {
color: #fff;
background-color: var(--c1-t2);
border-color: var(--c1);
}
.menu1 li.active,
.menu2 .menu3 li.active {
color: #fff;
background-color: #000;
border-color: var(--c1);
}
.menu1 li:active img,
.menu1 li.active img {
-webkit-filter: brightness(1);
filter: brightness(1);
}
.menu2 {
width: 100%;
height: 170rem;
padding: 0 10rem 0 10rem;
flex-flow: row wrap;
align-content: flex-end;
}
.menu2 li,
.menu2 li:hover {
width: calc(50% - 10rem);
height: 50rem;
padding: 0;
margin: 5rem;
display: inline-flex;
align-items: center;
justify-content: center;
font-size: 13rem;
color: var(--gc);
border: 1px solid var(--c1);
border-radius: 6rem;
}
.menu2 li:active { background-color: var(--c1-t2) }
.menu2 li.liLinks,
.menu2 li.liLinks:hover,
.menu2 li.liLinks:active,
.menu2 li.liLinks.active {
width: 100%;
height: 30rem;
padding: 0 5rem 3rem 5rem;
margin: 0;
display: block;
text-align: right;
background-color: transparent;
border: none;
border-radius: 0;
}
.menu2 li.liLinks span {
padding: 10rem 0 3rem 0;
margin: 0 5rem;
display: inline-block;
font-size: 11rem;
color: var(--g6);
border-bottom: 1px solid var(--g3);
}
.menu2 li.liLogo,
.menu2 li.liLogo:hover,
.menu2 li.liLogo:active,
.menu2 li.liLogo.active {
width: 100%;
height: calc(100% - 40px);
background-color: transparent;
border: none;
border-radius: 0;
}
.menu2 li.liLogo img { height: 40rem }
.menu2 li.liHelp,
.menu2 li.liHelp:hover,
.menu2 li.liHelp:active {
width: 100%;
height: 100%;
padding-top: 40rem;
display: block;
border: none;
background-color: transparent;
}
.menu2 li.liHelp { margin: 0 }
/* - - - - - - - - - - - - - - - / MENU */
/* - - - - - - - - - - - - - - - WIDGET */
.widget-control-area {
width: 100%;
height: 60rem;
padding: 0 3rem 3rem 3rem;
position: fixed;
top: initial;
right: initial;
left: 0;
bottom: 0;
background-color: rgba(0,0,0,.75);
}
.widget-control { flex-flow: row nowrap }
.widget-notifications {
width: 40rem;
height: 100%;
position: relative;
padding: 8rem 0;
/* line-height: 29rem; */
}
.widget-notifications img { height: 100% }
.widget-section {
width: calc( 100% - 40rem  );
height: 100%;
}
.widget-notifications .notif-number {
width: 14rem;
height: 14rem;
padding: 0;
display: block;
position: absolute;
right: 4rem;
top: 4rem;
color: var(--g35);
text-align: center;
line-height: 14rem;
background-color: var(--g35);
border-radius: 50%;
}
.widget-notifications .notif-number.active {
color: #fff;
background-color: var(--c7);
}
.widget-section li {
width: 25%;
height: 100%;
padding: 0 0 0 3rem;
display: block;
float: right;
}
.widget-section li div { padding: 8rem 0 }
.widget-section li div.bigIcon { padding: 4rem 0 }
.widget-section li div img { height: 100% }
/* - - - - - - - - - - - - - - - / WIDGET */

/* - - - - - - - - - - - - - - - TABS */
.tabs {
width: 100%;
height: 44rem;
padding: 0 15rem;
display: flex;
flex-flow: row nowrap;
gap: 10px;
position: absolute;
bottom: -54rem;
border: none;
}

.tabs li {
width: initial;
height: 44rem;
margin-left: initial;
display: flex;
justify-content: center;
align-items: center;
flex: 1 1 auto;
line-height: initial;
color: var(--g9);
background-color: var(--g1);
border: 1rem solid transparent;
border-radius: 0;
}
.tabs li.col-6 { flex: 1 1 50% }

.tabs li.active,
.tabs li:active {
color: #fff;
background-color: var(--g1);
border: 1rem solid var(--c1);
border-bottom: 1rem solid var(--c1);
}
.tabs li:active { background-color: var(--c1-t2) }

/* - - - - - - - - - - - - - - - / TABS */

/* - - - -
CATALOG PORTRAIT
SUMMARY (BACK) PORTRAIT
- - - - */
section#catalog,
section#back {
left: 0;
top: 100%;
}
section#catalog.active,
section#back.active {
top: 0;

}
#catalog-port,
#back-container-0 {
width: calc(100% - 10rem);
height: calc( 100% - 66rem);
display: block;
position: absolute;
left: 5rem;
bottom: -100%;
background-color: #000;
border-radius: 20rem 20rem 0 0;
transition: all .25s ease-in-out;
/* height: calc( 100% - 101rem); */
}
#back-container-0 {
margin-left: 0;
margin-right: 0;
}
section#catalog.active #catalog-port,
section#back.active #back-container-0 {
bottom: 60rem;
}

/* - - - - /
/ CATALOG PORTRAIT
/ SUMMARY (BACK) PORTRAIT
- - - - */

/* - - - - CATALOG PORTRAIT */
#catalog-menu-port {
width: 100%;
height: 60rem;
padding: 0 10rem;
position: absolute;
left: 0;
bottom: 0;
}
#catalog-menu-port li{
width: 14.285%;
height: 60rem;
padding: 9rem;
display: inline-flex;
align-items: center;
justify-content: center;
}
#catalog-menu-port li img {
-webkit-filter: brightness(.35);
filter: brightness(.35);
}
#catalog-menu-port li:active img {
-webkit-filter: brightness(1);
filter: brightness(1);
}
#catalog-menu-port li.active img {
-webkit-filter: brightness(0) saturate(100%) invert(68%) sepia(55%) saturate(767%) hue-rotate(31deg) brightness(108%) contrast(95%);
filter: brightness(0) saturate(100%) invert(68%) sepia(55%) saturate(767%) hue-rotate(31deg) brightness(108%) contrast(95%);
}
#games-port-container {
width: 100%;
height: calc(100% - 108rem );
padding: 0 10rem;
overflow: hidden;
}
.games-port-content {
width: 100%;
height: 100%;
position: relative;
display: flex;
flex-flow: row wrap;
overflow: hidden;
overflow-y: auto;
justify-content: stretch;
align-content: flex-start;
/* gap: 4px; */
}
.games-port-content .game-port,
.games-port-content .game-live-port {
/* width: calc(50% - 2px); */
width: 50%;
position: relative;
background-color: #000;
border: 1rem solid #000;
}
.games-port-content .game-port { height: 26vw }

.games-port-content .game-live-port { height: 25.5vw }
.game-cover .b-game {
width: 40rem;
height: 40rem;
position: absolute;
}
.game-cover .b-game.b-info { left: 4rem; top: 2rem }
.game-cover .b-game.b-favorite { left: 4rem; bottom: 2rem }
.game-cover .b-game.b-play {
margin-top: -20rem;
right: 22rem;
top: 50%;
border-radius: 50%;
background-color: rgba(0,0,0,.5);
}
.game-cover .b-game.b-play:active { background-color: #000 }
.game-cover .b-game.b-info:active img,
.game-cover .b-game.b-favorite:active img,
.game-cover .b-game.b-play:active img {
-webkit-filter: brightness(0) invert(88%) sepia(32%) saturate(5457%) hue-rotate(30deg) brightness(106%) contrast(95%);
filter: brightness(0) invert(88%) sepia(32%) saturate(5457%) hue-rotate(30deg) brightness(106%) contrast(95%);
}
.game-port .game-logo,
.game-live-port video,
.game-live-port img {
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
}
.game-live-port .game-title {
width: 100%;
padding: 3rem;
position: absolute;
left: 0;
bottom: 0;
font-size: 10rem;
text-align: center;
line-height: 16rem;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
color: var(--gc);
background-color: rgba(0,0,0,.5);
-webkit-filter: drop-shadow(1rem 1rem 4rem #000);
filter: drop-shadow(1rem 1rem 4rem #000);
}

#game-port-info-cover {
width: 100%;
height: 100%;
height: calc(100% - 60rem);
position: fixed;
left: 0;
top: 100%;
}
#game-port-info-cover.active { top: 0 }

#game-port-info {
width: calc(100% - 10rem);
height: calc(100% - 5rem );
/* max-height: 0; */
padding: 0 10rem;
position: absolute;
left: 5rem;
top: 100%;
background-color: #000;
border-radius: 20rem 20rem 0 0;
overflow: hidden;
transition: all .2s ease-in-out;
}
/* #game-port-info.active { max-height: calc(100% - 5rem ) } */
#game-port-info-cover.active #game-port-info {
/* max-height: calc(100% - 5rem ); */
top: 5rem;
}

.game-info {
height: calc(100% - 90rem);
padding: 5rem 20rem 0 20rem;
display: flex;
flex-flow: column nowrap;
justify-content: space-between;
background-color: var(--g1);
}
#game-port-home-info .game-info { height: calc(100% - 87rem) }

#game-port-home-cover {
width: 100%;
height: 100%;
position: fixed;
top: 100%;
z-index: 80000;
}
#game-port-home-cover.active { top: 0 }
#game-port-home-info {
width: calc(100% - 10rem);
height: calc(100% - 65rem );
padding: 0 10rem 20rem 10rem;
position: absolute;
left: 5rem;
background-color: #000;
overflow: hidden;
border-radius: 20rem 20rem 0 0 ;
transition: all .2s ease-in-out;
}

.fav-indicator { width: 26rem; height: 26rem }
.game-info-title { transform: translateY(2rem) }
span.info-txt {
height: 16rem;
display: block;
}
span.info-txt span { display: inline }
.info-coin {
width: 12.5%;
padding-top: 10rem;
display: inline-block;
font-size: 9px;
text-align: center;
}
.info-coin img { width: 18rem; height: 18rem }
/* - - - - / CATALOG PORTRAIT */

/* - - - - SUMMARY (BACK) PORTRAIT */
#back-container-0 { padding: 0 15rem }
.back-balances-container { display: block }
.back-container-portrait { height: calc(100% - 188rem) }
.back-container-1 {
height: 100%;
padding: 0;
flex-flow: row wrap;
align-items: stretch;
overflow: hidden;
overflow-y: auto;
gap: calc(10rem);
}
.balance {
width: calc(50% - 5rem);
margin: 0;
position: relative;
background-color: var(--g1);
border: 1px solid transparent;
}
.balance.selected { border-color: var(--c1)}
.balance-amount {
display: flex;
flex-flow: row nowrap;
align-items: center;
border: none;
}
.balance-amount img {
width: 36rem;
height: 36rem;
margin: 10rem;
}
.balance-amount .balance-name { font-size: 14rem }


.balance-amount,
.selected .balance-amount,
.balance-bono,
.selected .balance-bono {
border: none;
}

/* - - - - / SUMMARY (BACK) PORTRAIT */

/* - - - - EXCHANGER PORTRAIT */
#w-exchanger-cover.w-cover {
height: calc(100% - 60rem);
left: 0;
top: 100%;
}
#w-exchanger-cover.w-cover.active { top: 0 }

#w-exchanger {
width: calc(100% - 10rem);
height: calc(100% - 40rem);
padding: 0;
display: block;
position: absolute;
left: 5rem;
bottom: -100%;
background-color: #000;
border-radius: 20rem 20rem 0 0;
transition: all .25s ease-in-out;
}
#w-exchanger-cover.w-cover.active #w-exchanger {
bottom: 0rem;
}

#w-exchanger .coin,
#exchanger .coin {
width: 44rem;
height: 44rem;
}
.exchanger-convert-icon {
width: 44rem;
height: 44rem;
padding: 5rem;
margin: -10rem auto;
position: relative;
background-color: #000;
border-radius: 50%;
}
.exchanger-convert-icon img { transform: rotate(90deg) }
.wallet-detail {
padding: 30rem 15rem 15rem 15rem;
position: relative;
background-color: var(--g1);
}
.wallet-detail .wallet-coin {
position: absolute;
left: 15rem;
top: 20rem;
}
.btn2-actions {
width: 100%;
/* padding: 10rem 15rem; */
position: absolute;
left: 0;
bottom: 0;
}

.btn-lang,
.btn-lang-sm {
position: relative;
color: #fff;
border: 1px solid var(--g8);
}
.btn-lang {
width: 90rem;
height: 28rem;
font-size: 14rem;
padding-left: 15rem;
border-radius: 14rem;
}
.btn-lang-sm {
width: 80rem;
height: 20rem;
font-size: 12rem;
padding-left: 10rem;
border-radius: 10rem;
}
.btn-lang-sm.lang-dark { color: var(--g3) }

.btn-lang img.icon,
.btn-lang-sm img.icon {
height: auto;
position: absolute;
top: 3rem;
left: 3rem;
-webkit-filter: invert(55%) sepia(7%) saturate(91%) hue-rotate(131deg) brightness(93%) contrast(90%);
        filter: invert(55%) sepia(7%) saturate(91%) hue-rotate(131deg) brightness(93%) contrast(90%);
}
.btn-lang img.icon { width: 20rem }
.btn-lang-sm img.icon { width: 12rem }

.btn-lang:active,
.btn-lang-sm:active {
background-color: var(--g8);
}
.btn-lang:active img.icon,
.btn-lang-sm:active img.icon {
-webkit-filter: invert(1);
        filter: invert(1);
}
.btn-lang-sm.lang-dark:active {
color: #fff;
background-color: var(--g3);
}
/* - - - - / EXCHANGER PORTRAIT */

/* - - - - NOTIFICACIONES PORTRAIT */
#w-notify-cover.w-cover {
height: calc(100% - 60rem);
left: initial;
top: 100%;
}
#w-notify-cover.w-cover.active { top: 0 }

#notify.window {
position: absolute;
bottom: -100%;
transition: all .25s ease-in-out;
}
#w-notify-cover.w-cover.active #notify.window { bottom: 0 }
.notify-container { height: calc(100% - 54rem) }
.notificacion {
margin-bottom: 10rem;
padding: 15rem 30rem 15rem 15rem;
position: relative;
transition: all .2s ease-in-out;
}
.notificacion.deleted {
margin-bottom: 0;
padding: 0 30rem 0 15rem;
overflow: hidden;
}
.del-notify {
width: 20rem;
height: 20rem;
position: absolute;
right: 5rem;
bottom: 5rem;
cursor: pointer;
}
.del-notify img {
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
pointer-events: none;
-webkit-filter: brightness(0) invert(42%) sepia(90%) saturate(836%) hue-rotate(337deg) brightness(102%) contrast(101%);
filter: brightness(0) invert(42%) sepia(90%) saturate(836%) hue-rotate(337deg) brightness(102%) contrast(101%);
}
.del-notify:active img {
-webkit-filter: none;
filter: none;
}
/* - - - - / NOTIFICACIONES PORTRAIT */

/* - - - - PLAYGROUND PORTRAIT */
#playground-container {
width: 100%;
height: calc(100% - 60rem);
}
/* - - - - / PLAYGROUND PORTRAIT */

/* - - - - WALLET PORTRAIT */
section#wallet { padding: 40rem 0 70rem 0 }
.wallet-container {
display: flex;
flex-flow: column;
justify-content: space-between;
}
.wallets {
height: 165rem;
padding: 0 15rem;
display: flex;
flex-flow: row wrap;
gap: 5rem;
overflow: hidden;
overflow-x: auto;
align-content: flex-start;
}
.wallets.disabled {
opacity: .5;
pointer-events: none;
}
.wallets .wallet-item {
width: 22vw;
width: calc(25% - 3.75rem);
height: 80rem;
padding: 10rem 5rem;
margin: 0;
display: block;
text-align: center;
background-color: var(--g1);
border: 1px solid transparent;
border-radius: 0;
}
.wallets .wallet-item:active,
.wallets .wallet-item.selected { border-color: var(--c1) }
.wallet-coin {
width: 24rem;
height: 24rem;
display: inline-block;
}
.wallet-detail {
padding: 30rem 15rem 15rem 15rem;
position: relative;
}
.wallet-detail .wallet-coin {
width: 36rem;
height: 36rem;
}
.wallet-detail h1 { font-size: 40rem }

@-webkit-keyframes rotating {
from{-webkit-transform: rotate(0)}
to{-webkit-transform: rotate(360deg)}
}

@keyframes rotating {
from{-webkit-transform: rotate(0)}
to{-webkit-transform: rotate(360deg)}
}
.spinning { -webkit-animation: rotating 2s linear infinite; animation: rotating 2s linear infinite }

#home-conversion-coin,
#bono-activate-2 { height: calc( 100% - 40rem) }

.add-coin-container,
.dev-coin-container-2 {
height: calc(100% - 140rem);
display: flex;
gap: 10rem;
flex-flow: row wrap;
overflow: hidden;
overflow-y: auto;
align-content: flex-start;
}
.inner-dev-bono { height: calc(100% - 120rem) }
.dev-coin-container-2 { height: calc(100% - 94rem) }
#add-coin .coin,
#home-conversion-coin .coin,
.dev-coin-container-2 .coin {
width: calc(50% - 5rem);
height: auto;
margin: 0;
padding:0;
display: flex;
flex-flow: row nowrap;
border-radius: 4rem;
}
#add-coin .coin-icon,
#home-conversion-coin .coin-icon,
#add-coin .coin-desc,
#home-conversion-coin .coin-desc,
.dev-coin-container-2 .coin-icon,
.dev-coin-container-2 .coin-desc {
width: 65rem;
height: 63rem;
display: flex;
justify-content: center;
align-items: center
}
#add-coin .coin-icon img,
#home-conversion-coin .coin-icon img,
.dev-coin-container-2 .coin-icon img {
width: 36rem;
height: 36rem;
}
#add-coin .coin-desc,
#home-conversion-coin .coin-desc,
.dev-coin-container-2 .coin-desc {
width: calc(100% - 65rem);
padding-right: 10rem;
padding-left: 0;
justify-content: flex-start;
}

#wallet-withdraw .coin-icon,
#wallet-deposit-1 .coin-icon {
width: 36rem;
height: 36rem;
padding-right: 10rem;
display: inline-block;
vertical-align: middle;
}

.deposit1-container { height: calc(100% - 150px) }

.qr-code {
width: 100%;
height: auto;
min-height: initial;
display: block;
}
/* - - - - / WALLET PORTRAIT */

/* - - - - VERIFICACIONES PORTRAIT */
.verificacion-container {
padding-bottom: 70rem;
position: relative;
}
.col.col-verif-L{
width: 100%;
height: auto;
position: absolute;
left: 0;
bottom: 70rem;
z-index: 2;
}
#menu-verif,
#menu-datos {
flex-flow: row nowrap;
padding: 0 5rem;
gap: 4rem;
}
#menu-verif li {
height: 75rem;
padding: 10rem 0;
flex-direction: column;
justify-content: center;
flex: 1 1 20%;
font-size: 10rem;
color: var(--g9);
background-color: var(--g1);
border: 1px solid transparent;
}
#menu-verif li .indicator { padding: 0 }
#menu-verif li img {
width: auto;
height: 36rem;
border: none;
-webkit-filter: brightness(.35);
filter: brightness(.35);
}
#menu-verif li:active {
color: #fff;
background-color: var(--c1-t2);
border-color: var(--c1);
}
#menu-verif li.active {
background-color: #000;
border-color: var(--c1);
}
#menu-verif li:active img,
#menu-verif li.active img {
background-color: transparent;
border: none;
-webkit-filter: brightness(1);
filter: brightness(1);
}
#menu-verif li .indicator::after {
width: 16rem;
height: 16rem;
right: -2rem;
top: -2rem;
}

.col.col-verif-R {
width: 100%;
height: 100%;
padding: 10rem 15rem 75rem 15rem;
z-index: 1;
}
.flex-y-alt {
display: flex;
flex-flow: column nowrap;
justify-content: space-between;
align-items: center;
}
.verif-txt {
height: 96rem;
padding: 15rem 15rem 0 15rem;
background-color: var(--g1);
}
.w-tooltip {
padding-right: 35rem;
position: relative;
}
.verif-txt .tooltip2 {
position: absolute;
right: 5rem;
bottom: 5rem;
}
.verif-txt .tooltip2-contentainer {
width: 100%;
position: absolute;
top:96rem;
left: 0;
}

#verificaciones h1 {
font-size: 14rem;
text-align: center;
color: #fff;
}
#verificaciones p {
font-size: 12rem;
line-height: 1.5;
color: var(--gc);
}
#verificaciones p b {
font-weight: 400;
color: #fff;
}
.verification-area {
width: 100%;
height: calc(100% - 196rem);
display: flex;
flex-flow: row wrap;
gap: 10rem;
overflow: hidden;
overflow-y: auto;
/* margin-bottom: 10rem; */
}
.verification-area.va-2 { height: calc(100% - 216rem) }

.doc-container {
width: calc(33% - 5.6rem);
height: 150rem;
padding: 5rem;
}
.doc-container img.doc {
width: 100%;
height: 100%;
-o-object-fit: contain;
object-fit: contain;
}
.doc-container button{
position: absolute;
left: 5rem;
bottom: 5rem;
}
/* - - - - / VERIFICACIONES PORTRAIT */

/* - - - - RETIROS PORTRAIT */
section#retiros {
width: 100%;
padding-top: 50rem;
padding-bottom: 124rem;
}
/* - - - - / RETIROS PORTRAIT */
/* - - - - BONOS PORTRAIT */
section#bonos {
width: 100%;
padding-top: 50rem;
padding-bottom: 124rem;
}
#bonos-historial {
width: 100%;
padding-top: 50rem;
padding-bottom: 70rem;
}
.bonos-container {
height: 100%;
padding: 0 15rem;
}
.bonos-content {
overflow: hidden;
overflow-y: auto;
}
.bono-item {
margin-bottom: 10rem;
padding: 15rem 10rem 10rem 10rem;
display: block;
background-color: var(--g1);
border: 1rem solid transparent;
border-radius: 0;
}
.bono-item:last-child { margin-bottom: 0 }
.bono-coin {
width: 12.5%;
display: inline-block;
font-size: 10rem;
text-align: center;
}
/* - - - - / BONOS PORTRAIT */

/* - - - - DATOS PORTRAIT */
section#cuenta,
section#movimientos,
section#cta-games,
section#movimientos,
section#cuenta-afiliado {
width: 100%;
padding-top: 60rem;
padding-bottom: 62rem;
}
section#movimientos { padding-bottom: 124rem }
.datos-container {
overflow: hidden;
overflow-y: auto;
}
#menu-datos {
height: 54rem;
padding-top: 10rem;
}
#menu-datos li {
height: 44rem;
padding: 5rem;
display: flex;
justify-content: center;
align-items: center;
flex: 1 1 25%;
font-size: 10rem;
color: var(--g9);
background-color: var(--g1);
border: 1rem solid transparent;
}
#menu-datos li.active,
#menu-datos li:active {
color: #fff;
border-color: var(--c1);
}
#menu-datos li.active { background-color: #000 }
#menu-datos li:active { background-color: var(--c1-t2) }
#menu-datos li:focus { outline: none }
/* - - - - / DATOS PORTRAIT */

/* - - - - ACCORDION2 PORTRAIT */
.accordion2 {
position: relative;
background-color: var(--g1);
}
.accordion2.acc2ticket { background-color: var(--g3) }
.accordion2-header {
width: 100%;
height: 44rem;
padding-left: 10rem;
position: relative;
font-size: 16rem;
line-height: 44rem;
color: var(--c4-l);
}
.accordion2-header.ahw-title {
line-height: 40rem;
height: 68rem;
}
.accordion2-header .title { line-height: 1 }
.accordion2-indicator {
width: 44rem;
height: 44rem;
padding: 13rem;
position: absolute;
right: 0;
top: 0;
line-height: 1;
}
.accordion2 .accordion2-indicator img { transition: all .2s ease-in-out }
.accordion2.active .accordion2-indicator img { transform: rotate(180deg) }
.accordion2-content {
padding: 0 10rem;
max-height: 0;
overflow: hidden;
transition: all .2s ease-in-out;
}
.accordion2.active .accordion2-content {
padding: 10rem;
max-height: 280rem;
}
.accordion2 .accordion2-content.acc2cntnt-lg {
max-height: 0;
padding: 0 10rem;
}
.accordion2.active .accordion2-content.acc2cntnt-lg {
padding: 0 10rem 10rem 10rem;
max-height: 700rem;
}

.edit { display: none }
.edit.active { display: block }

.accordion2-vertical {
width: 0;
display: flex;
flex-flow: row nowrap;
justify-content: flex-start;
overflow: hidden;
}
.active + .accordion2-vertical {
width: initial;
overflow: initial;
}
/* - - - - / ACCORDION2 PORTRAIT */

/* - - - - AFILIADOS PORTRAIT */
section#home-afiliado { padding: 50rem 0 62rem 0 }
section#cuenta-afiliado { padding-top: 50rem }
.tab-container-afiliados-0 { height: calc(100% - 63rem) }
.tab-container-afiliados-3 { height: calc(100% - 35rem) }

.afiliado-resume {
padding: 0 10rem;
display: flex;
flex-flow: row nowrap;
align-items: stretch;
gap: 10rem;
}
.afiliado-resume-card {
height: 130rem;
padding: 35rem 5rem 15rem 5rem;
display: flex;
flex-flow: column nowrap;
justify-content: space-between;
flex: 1 1 33.333%;
text-align: center;
border-radius: 4rem;
}

.afiliados-content {
overflow: hidden;
overflow-y: auto;
}
.campaign {
margin-bottom: 10rem;
padding: 15rem 10rem 10rem 10rem;
background-color: var(--g1);
}
.campaign .title {
font-size: 14rem;
font-weight: 500;
}
#campaign-info { padding-bottom: 20rem }
#new-link { padding-bottom: 20rem }

.afiliados-balances-container {
display: flex;
flex-flow: row wrap;
align-content: flex-start;
gap: 10rem;
}
.empty-balance { width: calc(100% - 10rem) }
.balance-card {
width: calc( 50% - 5rem );
padding: 10rem 15rem 15rem 10rem;
background-color: var(--g1);
border: 1rem solid transparent;
}
.balance-card.selected { border-color: var(--c1) }
.balance-card .coin {
width: 36rem;
height: 36rem;
}

/* - - - - / AFILIADOS PORTRAIT */

/* - - - - TICKETS PORTRAIT */
section#tickets { padding: 50rem 0 65rem 0 }
.tickets-i-container {
height: 100%;
position: relative;
border-bottom: none;
overflow: hidden;
overflow-y: auto;
}
.tickets-i-container .accordion2 .accordion2-content {
max-height: 0;
padding-top: 0;
overflow: hidden;
overflow-y: auto;
}
.tickets-i-container .accordion2.active .accordion2-content {
max-height: var(--acc2ContH);
}
.ticket-msg.admin {
margin-left: 0;
background-color: var(--g1);
}
.ticket-msg.client {
margin-left: 10%;
background-color: var(--c4-t3)
}

#comTableContainer { max-height: var(--acc2ContH2) }

/* - - - - / TICKETS PORTRAIT */

/* - - - - LEGAL PORTRAIT */
.legal-container-0 {
height: calc(100% - 131rem);
padding-bottom: 0;
}
/* - - - - / LEGAL PORTRAIT */

/* - - - - WINDOWS PORTRAIT */
.window {
width: calc(100% - 10rem);
max-width: 100%;
height: initial;
display: block;
position: absolute;
top: initial;
right: initial;
left: 5rem;
bottom: 0;
background-color: #000;
border-radius: 20rem 20rem 0 0;
}

.window-pb8 { padding-bottom: 84rem }

#login,
#add-coin,
#home-conversion-coin,
#bono-activate-0,
#wallet-withdraw,
#wallet-deposit-1,
#pre-deposit.window {
padding-bottom: 0;
}
#login,
#notify,
#registro,
#add-coin,
#wallet-withdraw,
#wallet-deposit-1,
#afiliado-new-account,
#filtro-retiros,
#filtro-bonos,
#filtro-cta-movimientos,
#filtro-cta-crypto,
#filtro-cta-crypto-tx,
#filtro-cta-games,
#ayuda-call,
#new-ticket,
#new-link,
#filtro-comisiones,
#filtro-trafico
{ height: calc( 100% - 40rem) }
#filter-catalog { height: calc( 100% - 5rem) }

#filter-catalog,
#filtro-retiros,
#filtro-bonos,
#filtro-cta-movimientos,
#filtro-cta-crypto,
#filtro-cta-crypto-tx,
#filtro-cta-games,
#home-game-info {
padding: 0 0 84rem 0;
background-color: #000;
}

#wc-catalog.w-cover {
top: 100%;
z-index: 82000;
}
#wc-catalog.w-cover.active { top: 0 }

.invalid {
height: auto;
overflow: initial;
overflow-y: initial;
}
.invalid p {
font-size: 12rem;
line-height: 14rem;
}

.register-container { height: calc( 100% - 47rem ) }
.register {
width: 100%;
/* position: absolute; left: 0; bottom: 0; */
}

/* - - - - LANGUAGES */
.lang-cover {
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
position: fixed;
top: 0;
left: 0;
background-color: rgba(0,0,0,.75);
z-index: 91100;
/* border-radius: 20rem 20rem 0 0; */
}
.languages { width: 100% }
.lang-list { list-style-type: none }
.lang-list li,
.lang-list li:hover {
width: 100%;
/* height: 56rem; */
padding: 10rem;
display: block;
font-size: 16rem;
color: var(--gc);
cursor: pointer;
-webkit-user-select: none;
   -moz-user-select: none;
    -ms-user-select: none;
        user-select: none;
border: 1rem solid transparent;
}
.lang-list li img {
width: 30rem;
height: 30rem;
margin-right: 10rem;
display: inline-block;
vertical-align: middle;
}
.lang-list li:active {
background-color: var(--g3);
}

.lang-list li.disabled {
color: var(--g6);
pointer-events: none;
}
.lang-list li.selected { border-color: var(--c1);}
.lang-list li.disabled img { opacity: .25;}
/* - - - - LANGUAGES */

#registro p { display: block }ß
#registro p.txt-a,
#registro p.txt-a:hover { color: var(--gc) }
#registro p.txt-a:active { color: #fff}

#reglas { padding-bottom: 0 }
/* - - - - WINDOWS PORTRAIT */

/* - - - - WINDOWS TRANSITION */
#main-menu-cover,
section#catalog,
section#back,
#w-exchanger-cover.w-cover,
#w-notify-cover.w-cover,
#game-port-home-cover,
.coverB
{
background-color: transparent;
-webkit-backdrop-filter: blur(0);
backdrop-filter: blur(0);
transition: top 0s linear .25s, background-color .25s ease-in-out, -webkit-filter .25s ease-in-out;
transition: top 0s linear .25s, background-color .25s ease-in-out, filter .25s ease-in-out;
transition: top 0s linear .25s, background-color .25s ease-in-out, filter .25s ease-in-out, -webkit-filter .25s ease-in-out;
}

#main-menu-cover.active,
section#catalog.active,
section#back.active,
#w-exchanger-cover.w-cover.active,
#w-notify-cover.w-cover.active,
#game-port-home-cover.active,
.coverB.active
{
background-color: var(--g6t);
-webkit-backdrop-filter: blur(1.5px);
backdrop-filter: blur(1.5px);
transition: top 0s linear 0s, background-color .25s ease-in-out, -webkit-filter .25s ease-in-out;
transition: top 0s linear 0s, background-color .25s ease-in-out, filter .25s ease-in-out;
transition: top 0s linear 0s, background-color .25s ease-in-out, filter .25s ease-in-out, -webkit-filter .25s ease-in-out;
}

.coverB .window {
bottom: -100%;
transition: bottom .25s ease-in-out;
}
.coverB.active .window { bottom: 0 }

#game-port-home-cover #game-port-home-info {
top: 100%;
transition: top .25s ease-in-out;
}
#game-port-home-cover.active #game-port-home-info { top: 5rem }
/* - - - - / WINDOWS TRANSITION */

/* -- */
#btn-homeV-help {
position: fixed;
right: 10rem;
bottom: 55rem;
}

.w-title-cover { height: 24rem }
.port-rel { position: relative }
/* -- */

.noScrlBr::-webkit-scrollbar {
display: none;
}

}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / QUERY */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - QUERY */
/* (orientation: PORTRAIT ) */
@media (max-aspect-ratio: 13/9) {
   html {
   height: initial;
   font-size: 1.15px;
   }
   .home-catalog { height: calc(23.16vw * 5) }
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / QUERY */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - QUERY */
/* (orientation: PORTRAIT SMALL) */
@media only screen and (max-aspect-ratio: 13/9) and (max-device-height: 669px) {

   html { font-size: .95px }
   .home-catalog { height: calc(23.16vw * 4) }

}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / QUERY */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - QUERY */
/* (orientation: PORTRAIT MID ) */
@media only screen and (max-aspect-ratio: 13/9) and (max-device-height: 648px) {

   html { font-size: .9px }
   .home-catalog { height: calc(23.16vw * 4) }

}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - / QUERY */








.test,
.test th,
.test td {
border: 1px dashed #ff0 !important
}
.testr { border: 1px dashed #f00 !important }

.testr { border: 1px dashed #f00 !important }
.testb { background-color: rgba(255, 0, 0, .25) }

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - QUERY */
